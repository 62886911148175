import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from '../../store';
import { hasUserIntegrateIOID } from '../../account/store/account.selectors';
import { CustomMiddleClickEvent } from '../helper/global-types.helper';
import { ChangeStateService } from '../services/change-state.service';

@Component({
  selector: 'sidebar-sub',
  template: `
    <div class="sidebar-sub" *ngIf="isSubWrapperVisible">
      <div class="sub-menu">
        <strong class="sub-menu-header">{{ 'sidebar.sub-menu.headers.account-settings' | translate }}</strong>
        <ul class="list-unstyled">
          <li [ngClass]="{ active: isStateActive('settings/account/profile') }" *ngxPermissionsOnly="'viewBillingInfo'">
            <a
              (click)="changeState('settings/account/profile', $event)"
              (auxclick)="changeState('settings/account/profile', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.information' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/account/billing') }" *ngxPermissionsOnly="'viewBillingInfo'">
            <a
              (click)="changeState('settings/account/billing', $event)"
              (auxclick)="changeState('settings/account/billing', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.billing' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/account/usage') }" *ngxPermissionsOnly="'viewUsage'">
            <a
              (click)="changeState('settings/account/usage', $event)"
              (auxclick)="changeState('settings/account/usage', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.usage' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/account/hooks') }" *ngxPermissionsOnly="'listHooks'">
            <a
              (click)="changeState('settings/account/hooks', $event)"
              (auxclick)="changeState('settings/account/hooks', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.hooks' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/account/members') }" *ngxPermissionsOnly="'listMembers'">
            <a
              (click)="changeState('settings/account/members', $event)"
              (auxclick)="changeState('settings/account/members', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.members' | translate }}</a
            >
          </li>
          <li
            [ngClass]="{ active: isStateActive('settings/account/global-variables') }"
            *ngxPermissionsOnly="'viewGlobalVariables'"
          >
            <a
              (click)="changeState('settings/account/global-variables', $event)"
              (auxclick)="changeState('settings/account/global-variables', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.global-variables' | translate }}</a
            >
          </li>
        </ul>
        <strong class="sub-menu-header">{{ 'sidebar.sub-menu.headers.your-settings' | translate }}</strong>
        <ul class="list-unstyled">
          <li [ngClass]="{ active: isStateActive('settings/my/profile') }">
            <a
              (click)="changeState('settings/my/profile', $event)"
              (auxclick)="changeState('settings/my/profile', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.personal_information' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/my/password') }" *ngIf="!(hasUserIntegrateIOID$ | async)">
            <a
              (click)="changeState('settings/my/password', $event)"
              (auxclick)="changeState('settings/my/password', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.change_password' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/my/mfa') }" *ngIf="!(hasUserIntegrateIOID$ | async)">
            <a
              (click)="changeState('settings/my/mfa', $event)"
              (auxclick)="changeState('settings/my/mfa', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.mfa' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/my/notifications') }">
            <a
              (click)="changeState('settings/my/notifications', $event)"
              (auxclick)="changeState('settings/my/notifications', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.notifications' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/my/keys') }">
            <a
              (click)="changeState('settings/my/keys', $event)"
              (auxclick)="changeState('settings/my/keys', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.ssh_keys' | translate }}</a
            >
          </li>
          <li [ngClass]="{ active: isStateActive('settings/my/accounts') }" *ngxPermissionsOnly="'listAccounts'">
            <a
              (click)="changeState('settings/my/accounts', $event)"
              (auxclick)="changeState('settings/my/accounts', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.accounts' | translate }}</a
            >
          </li>
        </ul>
        <strong class="sub-menu-header">{{ 'sidebar.sub-menu.headers.developer-settings' | translate }}</strong>
        <ul class="list-unstyled">
          <li [ngClass]="{ active: isStateActive('settings/developer/tokens') }">
            <a
              (click)="changeState('settings/developer/tokens', $event)"
              (auxclick)="changeState('settings/developer/tokens', { isMiddleClick: true })"
              >{{ 'sidebar.sub-menu.items.tokens' | translate }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class SidebarSubComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() toggleMenu = new EventEmitter<boolean>();
  isSubWrapperVisible = false;
  isWorkspacesItemActive = false;
  isBoomerang = false;
  hasUserIntegrateIOID$ = this.store.select(hasUserIntegrateIOID);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private changeStateService: ChangeStateService,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setMenuFlags();
    });
  }

  ngOnInit() {
    this.isBoomerang = !!document.getElementById('heroku-boomerang');
    this.setMenuFlags();
  }

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
    this.toggleMenu.emit(false);
  }

  isStateActive(stateName: string): boolean {
    return this.router.url.includes(stateName);
  }

  setMenuFlags() {
    this.isWorkspacesItemActive = !!this.router.url.match(/\/workspaces\/\w+/);
    this.isSubWrapperVisible = this.router.url.includes('/settings');
  }
}
