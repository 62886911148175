import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeClustersModal,
  getClustersList,
  loadMoreClustersList,
  openClustersModal,
  searchClustersList,
} from '../store/clusters.actions';
import {
  selectAllClustersLoadedFlag,
  selectClusters,
  selectClustersErrorFlag,
  selectClustersIsModalOpenFlag,
  selectClustersLoadingFlag,
  selectLastlyCreatedCluster,
} from '../store/clusters.selectors';
import { Cluster, CLUSTERS_VISIBLE_STATUSES, ClusterTypes } from '../clusters.models';
import { AssistedSearchClustersConfiguratorService } from '../../common/services/assisted-search/assisted-search-clusters-configurator.service';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { selectJobsIsModalOpenFlag } from '../../jobs/store/jobs.selectors';
import { closeJobsModal, openJobsModal } from '../../jobs/store/jobs.actions';
import { Job } from '../../jobs/jobs.models';
import { loadSelectPickerItems } from '../../common/store/select-picker.actions';
import {
  queryParamsMap,
  SelectPickerTypes,
} from '../../common/components/forms/select-picker/select-picker-types.enum';
import { QueryParamsClustersList } from '../../common/helper/query-params-generic-list.helper';
import { IdleTimeValues } from '../services/idle-times.service';

const defaultCluster: Partial<Cluster> = {
  name: '',
  type: ClusterTypes.sandbox,
  nodes: 1,
  terminate_on_idle: true,
  time_to_idle: IdleTimeValues.ThirtyMinutes,
  reuse_cluster_strategy: 'any',
};

@Component({
  selector: 'clusters-list',
  template: `
    <generic-list
      [type]="type"
      [items]="clusters$ | async"
      [isLoading]="clustersLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openClusterCreateModal()"
      [newBtnText]="'cluster.generic-object.buttons.new' | translate"
      [emptyTitle]="'cluster.generic-object.empty.title' | translate"
      [emptyDescription]="'cluster.generic-object.empty.description' | translate"
      (loadMore)="loadMoreClusters($event)"
      [allItemsLoaded]="areAllClustersLoaded$ | async"
      [limit]="queryParams.limit"
      [searchPlaceholder]="'cluster.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="true"
      [isError]="clustersError$ | async"
      (searchSubmit)="onSearchSubmit($event)"
    >
      <ng-template templateName="listItem" let-item>
        <cluster-list-item
          [item]="item"
          [searchValue]="searchValue"
          (edit)="editCluster($event)"
          (createJob)="openJobCreateModal($event)"
        ></cluster-list-item>
      </ng-template>
    </generic-list>
    <xp-modal
      [isOpen]="isClusterModalOpen$ | async"
      [closeButtonText]="'cluster.generic-object.buttons.close' | translate"
      [saveButtonText]="'cluster.generic-object.buttons.save' | translate"
      [titleText]="'cluster.generic-object.title' | translate"
      (close)="onClusterModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <cluster-form [item]="formItem"></cluster-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      [isOpen]="isJobModalOpen$ | async"
      [closeButtonText]="'job.generic-object.buttons.close' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      (close)="onJobModalClose()"
    >
      <ng-template>
        <job-form [item]="jobFormItem" [disableModalClose]="true" [ignoreLastlyCreatedCluster]="true"></job-form>
      </ng-template>
    </xp-modal>
  `,
})
export class ClustersListComponent implements AfterViewInit, OnInit, OnDestroy {
  type = GenericListType.clusters;
  clustersLoading$ = this.store.select(selectClustersLoadingFlag);
  clustersError$ = this.store.select(selectClustersErrorFlag);
  isClusterModalOpen$ = this.store.select(selectClustersIsModalOpenFlag);
  isJobModalOpen$ = this.store.select(selectJobsIsModalOpenFlag);
  areAllClustersLoaded$ = this.store.select(selectAllClustersLoadedFlag);
  clusters$ = this.store
    .select(selectClusters)
    .pipe(map((clusters) => clusters.filter((cluster) => CLUSTERS_VISIBLE_STATUSES.includes(cluster.status))));
  queryParams = QueryParamsClustersList;
  formItem: Partial<Cluster> = defaultCluster;
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  jobFormItem: Partial<Job> = {};
  clusterSubscription: Subscription;
  searchQuery = '';
  searchValue = '';

  constructor(
    private store: Store<AppState>,
    private assistedSearchClustersConfigurator: AssistedSearchClustersConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.clusterSubscription = this.store.select(selectLastlyCreatedCluster).subscribe((cluster) => {
      if (cluster) {
        this.store.dispatch(
          loadSelectPickerItems({
            id: 'cluster-picker-job-form',
            itemType: SelectPickerTypes.cluster,
            params: queryParamsMap[SelectPickerTypes.cluster],
          }),
        );
        this.jobFormItem = {
          ...this.jobFormItem,
          cluster_id: cluster.id,
        };
      }
    });
  }

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    this.searchQueryFromUrl = query || '';

    this.searchConfig = this.assistedSearchClustersConfigurator.getConfig();

    if (!query) {
      this.store.dispatch(getClustersList({ params: this.queryParams }));
    }
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/clusters/new')) {
      this.openClusterCreateModal();
    }

    if (this.router.url.match(/\/clusters\/\w+\/edit/)) {
      this.clusters$
        .pipe(
          filter((clusters) => clusters.length > 0),
          first(),
        )
        .subscribe((clusters) => {
          const { cluster_id } = this.route.firstChild?.snapshot.params;
          const foundCluster = clusters.find((item) => item.id === Number(cluster_id));
          if (!foundCluster) {
            this.router.navigate(['./'], { relativeTo: this.route });
          } else {
            this.editCluster(foundCluster);
          }
        });
    }
  }

  editCluster(item: Cluster) {
    this.formItem = { ...item };
    this.router.navigate([`${item.id}/edit`], { relativeTo: this.route });
    this.store.dispatch(openClustersModal());
  }

  openClusterCreateModal(): void {
    this.formItem = { ...defaultCluster };
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openClustersModal());
  }

  openJobCreateModal(cluster: Cluster): void {
    this.jobFormItem = {
      cluster_id: cluster.id,
    };
    this.store.dispatch(openJobsModal());
  }

  onClusterModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeClustersModal());
  }

  onJobModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeJobsModal());
  }

  loadMoreClusters(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreClustersList({ params }));
  }

  onSearchSubmit({ query, text }) {
    const params = { ...this.queryParams, q: query };
    this.queryParams = params;
    this.searchQuery = query;

    if (!query) {
      this.store.dispatch(getClustersList({ params: this.queryParams }));
    } else {
      this.store.dispatch(searchClustersList({ params }));
    }

    this.clustersLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        first(),
      )
      .subscribe(() => {
        this.searchValue = text || '';
      });
  }

  ngOnDestroy() {
    if (this.clusterSubscription) {
      this.clusterSubscription.unsubscribe();
    }
  }
}
