import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from '../../common/services/permissions.service';
import { Workspace } from '../workspaces.models';
import { NotifyService } from '../../common/services/notify.service';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { removeWorkspace } from '../store/workspaces.actions';

@Component({
  selector: 'workspace-list-item',
  template: `
    <div class="generic-list-item slider-animation" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="workspace-list-item folders" id="workspace-{{ item.id }}">
        <div class="workspace-background" (click)="goToWorkspace(item)">
          <img src="assets/img/workspace.svg" alt="Workspace" />
        </div>
        <div class="workspace-icon" (click)="goToWorkspace(item)">
          <div class="workspace-icon-container">
            <svg class="icon icons-master-expanded_icon-navmain-workspace">
              <use href="#icon-workspace"></use>
            </svg>
          </div>
        </div>
        <div class="workspace-name">
          <div class="name-description-container">
            <strong class="name-container">
              <a
                (click)="goToWorkspace(item)"
                class="workspace-title"
                [matTooltip]="item.name"
                matTooltipPosition="right"
                matTooltipClass="right wide"
                [innerHTML]="item.name | xpHighLight: searchValue"
              ></a>
            </strong>
            <p
              [matTooltip]="item.description"
              matTooltipPosition="right"
              matTooltipClass="right wide"
              class="workspace-description"
              [innerHTML]="item.description | xpHighLight: searchValue"
            ></p>
          </div>
        </div>
        <div class="workspace-owner">
          <a
            (click)="goToOwner(item)"
            [matTooltip]="ownerName"
            matTooltipPosition="right"
            matTooltipClass="right wide"
            >{{ ownerName | xpLengthCheck: 20 }}</a
          >
        </div>

        <div class="workspace-date">
          <span
            class="date-tooltip"
            [matTooltip]="item.updated_at | xpDateUTC"
            matTooltipPosition="above"
            matTooltipClass="above"
            >{{ item.updated_at | xpPrettyDate }}</span
          >
        </div>
        <div class="workspace-description">
          <span>{{ 'workspace.generic-list.item.labels.last-edit' | translate }}</span>
          <span
            class="date-tooltip"
            [matTooltip]="item.updated_at | xpDateUTC"
            matTooltipPosition="above"
            matTooltipClass="above"
            >{{ item.updated_at | xpPrettyDate }}</span
          >
          <span>{{ 'workspace.generic-list.item.labels.by' | translate }}</span>
          <a
            class="workspace-owner-description"
            [matTooltip]="ownerName"
            matTooltipPosition="above"
            matTooltipClass="above"
            (click)="goToOwner(item)"
            >{{ ownerName | xpLengthCheck: 13 }}</a
          >
        </div>
        <div class="packages-statuses">
          <div class="packages-statuses-container">
            <span
              class="packages-status total"
              matTooltipPosition="above"
              [matTooltip]="
                'workspace.generic-list.item.labels.packages-length' | translate: { length: item.package_count || 0 }
              "
              >{{ item.package_count }}</span
            >
            <span
              class="packages-status failed"
              matTooltipPosition="above"
              [matTooltip]="
                'workspace.generic-list.item.labels.packages-failed'
                  | translate: { length: item.last_failed_jobs_count || 0 }
              "
              >{{ item.last_failed_jobs_count }}</span
            >
            <span
              class="packages-status pending"
              matTooltipPosition="above"
              [matTooltip]="
                'workspace.generic-list.item.labels.packages-stopped'
                  | translate: { length: item.last_stopped_jobs_count || 0 }
              "
              >{{ item.last_stopped_jobs_count }}</span
            >
            <span
              class="packages-status success"
              matTooltipPosition="above"
              [matTooltip]="
                'workspace.generic-list.item.labels.packages-completed'
                  | translate: { length: item.last_completed_jobs_count || 0 }
              "
              >{{ item.last_completed_jobs_count }}</span
            >
          </div>
        </div>
        <div class="dropdown xp-dropdown" [matMenuTriggerFor]="dropdown" *ngxPermissionsOnly="'updateWorkspace'">
          <i class="fa fa-ellipsis-h"></i>
        </div>
        <mat-menu #dropdown="matMenu">
          <li mat-menu-item (click)="editWorkspace(item)" *ngxPermissionsOnly="'updateWorkspace'">
            <span>{{ 'workspace.generic-list.item.actions.edit' | translate }}</span>
          </li>
          <li mat-menu-item (click)="onRemove(item)" *ngxPermissionsOnly="'deleteWorkspace'">
            <span class="text-danger">{{ 'workspace.generic-list.item.actions.delete' | translate }}</span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class WorkspaceListItemComponent {
  @Input() item: Workspace;
  @Input() searchValue: string;
  @Output() edit = new EventEmitter<Workspace>();

  constructor(
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private notify: NotifyService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get ownerName(): string {
    const owner = this.item.modified_by || { name: '', email: '' };
    return owner.name || (owner.email.match(/\S+(?=@)/g) || [''])[0];
  }

  editWorkspace(item: Workspace) {
    this.edit.emit(item);
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  goToWorkspace(item: Workspace): void {
    this.router.navigate([`${item.id}`], { relativeTo: this.route });
  }

  goToOwner(item: Workspace): void {
    this.router.navigate([`/${this.route.parent.snapshot.params.account_id}/settings/account/members`]);
  }

  onRemove(item: Workspace): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('workspace.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('workspace.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('workspace.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('workspace.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.workspace,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(removeWorkspace({ workspaceId: item.id }));
      }
    });
  }
}
