import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { groupBy } from 'lodash';
import { ConnectionSchemaField } from '../../package.models';
import { SchemaImporterSettings } from '../../helpers/schema-importer-settings.helper';
import { ErrorType } from '../fields-collection/xp-fields-collection.component';
import { REGEXES } from '../../../constants/regexes';

interface Tab {
  active: boolean;
  name: string;
}

const filterPredicate = (data: any, filter: string) => {
  const { name, alias, data_type, projected_name, left, operator, right, function_name, field_name, column_name } =
    data;
  const dataStr = [
    name,
    alias,
    data_type,
    projected_name,
    left,
    operator,
    right,
    function_name,
    field_name,
    column_name,
  ]
    .filter(Boolean)
    .map((item) => item.toLowerCase())
    .join('');
  return dataStr.indexOf(filter) !== -1;
};

@Component({
  selector: 'connection-schema',
  template: `
    <div class="schema-importer-table-tabs">
      <div
        class="schema-importer-table-tab"
        *ngFor="let tab of tabs"
        [ngClass]="{ active: tab.active }"
        (click)="selectTab(tab.name)"
        [hidden]="tab.name === 'All' || tabs.length === 1 || hideTabs"
      >
        <span>{{ tab.name }}</span>
      </div>
    </div>
    <div class="connection-columns-search">
      <i class="fa fa-search"></i>
      <input
        class="connection-columns-search-input form-control input-sm"
        (keyup)="applyFilter($event)"
        placeholder="Search fields"
        #input
      />
    </div>
    <xp-loader *ngIf="isLoading"></xp-loader>
    <table
      *ngIf="!isLoading"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      [trackBy]="trackByName"
      cdkDropList
      [cdkDropListData]="dataSource"
      (cdkDropListDropped)="dropTable($event)"
    >
      <ng-container matColumnDef="row">
        <th mat-header-cell *matHeaderCellDef>Row</th>
        <td mat-cell *matCellDef="let element; let index = index" class="small-field gray">
          {{ index + paginator.pageIndex * paginator.pageSize + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sort">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let index = index" class="small-field">
          <div
            class="field-sort"
            cdkDragHandle
            (touchstart)="dragDisabled = false"
            (touchend)="dragDisabled = true"
            (mousedown)="dragDisabled = false"
            (mouseup)="dragDisabled = true"
          >
            <div class="editor-button dnd-button-schema-importer">
              <i class="fa fa-arrows-v"></i>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ settings.sourceDesginatorName }}</th>
        <td mat-cell *matCellDef="let element; let index = index" class="field-name">
          <span *ngIf="settings.showSourceDesignatorInSelectedFields && !settings.allowToEditSourceDesignator">{{
            element.name
          }}</span>
          <xp-input
            *ngIf="settings.showSourceDesignatorInSelectedFields && settings.allowToEditSourceDesignator"
            [ngModel]="element.name"
            (ngModelChange)="onNameChange($event, index + paginator.pageIndex * paginator.pageSize)"
            type="text"
            name="name"
            [isFocused]="element.focusedProp === 'name'"
          ></xp-input>
        </td>
      </ng-container>

      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef>Alias</th>
        <td mat-cell *matCellDef="let element; let index = index" class="field-alias">
          <xp-input
            [ngModel]="element.alias"
            (ngModelChange)="onAliasChange($event, index + paginator.pageIndex * paginator.pageSize)"
            type="text"
            name="alias"
            [isFocused]="element.focusedProp === 'alias'"
          ></xp-input>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="data_type">
        <th mat-header-cell *matHeaderCellDef class="field-type">Type</th>
        <td mat-cell *matCellDef="let element; let index = index" class="field-type">
          <span
            *ngIf="
              settings.unchangeableDatatypeFields.includes(element.name) ||
              !settings.allowToChangeDataType ||
              element.disableTypeEdit
            "
            >{{ 'variables-editor-row.selects.data_type.options.' + element.data_type | translate }}</span
          >
          <xp-select
            [value]="element.data_type"
            [options]="dataTypeOptions"
            (valueChange)="onDataTypeChange($event, index + paginator.pageIndex * paginator.pageSize)"
            class="small-field"
            [attr.id]="'data_type_' + element.name"
            name="data_type"
            panelClass="schema-importer-select"
            *ngIf="
              !settings.unchangeableDatatypeFields.includes(element.name) &&
              settings.allowToChangeDataType &&
              !element.disableTypeEdit
            "
          >
          </xp-select>
        </td>
      </ng-container>

      <!-- Add Column -->
      <ng-container matColumnDef="add_between">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let row; let index = index"
          class="field-btn-sm"
          (click)="addFieldItem(index + paginator.pageIndex * paginator.pageSize)"
        >
          <i class="fa fa-plus"></i>
        </td>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef class="field-btn-sm"></th>
        <td
          mat-cell
          *matCellDef="let row; let index = index"
          class="field-btn-sm"
          (click)="removeFieldItem(index + paginator.pageIndex * paginator.pageSize)"
        >
          <i
            *ngIf="
              this.id === 'selected-fields' &&
              (this.settings.allowToRemoveSelectedFields || this.settings.hasEditableFields || row.editable)
            "
            [ngClass]="{
              hidden: !(
                (!row.required && this.settings.allowToRemoveSelectedFields) ||
                (!row.required && row.editable)
              ),
            }"
            class="fa fa-minus"
          ></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="add">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let row; let index = index"
          class="field-btn-sm"
          [ngClass]="{ disabled: !(settings.add || settings.hasEditableFields || row.predefined) }"
          (click)="
            (settings.add || settings.hasEditableFields || row.predefined) &&
              addFieldItem(index + paginator.pageIndex * paginator.pageSize)
          "
        >
          <i
            class="fa fa-plus"
            *ngIf="id === 'available-fields' && (settings.add || settings.hasEditableFields || row.predefined)"
          ></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="getRowState(i)"
        cdkDrag
        [cdkDragData]="row"
        [cdkDragDisabled]="dragDisabled"
        (cdkDragReleased)="dragDisabled = true"
      ></tr>
    </table>

    <div *ngIf="!fields.length" class="empty-list">The list is empty</div>

    <button
      type="button"
      class="btn btn-xs btn-default pull-right btn-add-to-end"
      *ngIf="settings.allowToAddFieldsAtTheEnd"
      (click)="addToEndClick()"
    >
      <i class="fa fa-plus"></i> Add
    </button>

    <mat-paginator
      #paginator
      [hidden]="fields.length <= 10"
      [pageSizeOptions]="[10, 20, 50]"
      [pageSize]="10"
      [showFirstLastButtons]="true"
    ></mat-paginator>
    <xp-fields-collection-errors-box
      [errors]="errors"
      class="fields-collection-errors"
    ></xp-fields-collection-errors-box>
  `,
})
export class ConnectionSchemaComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fields: ConnectionSchemaField[] = [];
  @Input() selectedFields: ConnectionSchemaField[] = [];
  @Input() predefinedFields: ConnectionSchemaField[] = [];
  @Input() settings: Partial<SchemaImporterSettings>;
  @Input() displayedColumns: string[] = ['name', 'type', 'add'];
  @Input() id: string;
  @Input() hideTabs: boolean;
  @Input() isLoading: boolean = false;
  @Input() ignoreFieldNameValidation: boolean = false;
  @Output() addField = new EventEmitter<{ field: ConnectionSchemaField; index: number }>();
  @Output() addFieldToEnd = new EventEmitter();
  @Output() removeField = new EventEmitter<ConnectionSchemaField>();
  @Output() updateFields = new EventEmitter<ConnectionSchemaField[]>();
  @Output() updateValidation = new EventEmitter<boolean>();
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
      setTimeout(() => {
        this.dataSource.data = this.currentFields;
      });
    }
  }
  @ViewChild('table') table: MatTable<any>;
  dataSource: MatTableDataSource<any>;

  dataTypeOptions = [];
  errors: ErrorType[] = [];
  dragDisabled = true;
  tabs: Tab[] = [];
  fieldsMap: {
    [key: string]: ConnectionSchemaField[];
  };

  ngOnInit() {
    this.fieldsMap = this.hideTabs ? { Schema: this.fields } : groupBy(this.fields, 'category');
    this.tabs = this.hideTabs
      ? [{ name: 'Schema', active: true }]
      : Object.keys(this.fieldsMap).map((name) => ({ active: false, name }));

    if (this.tabs.length) {
      this.selectTab(this.tabs[0].name, false);
    }
    this.dataSource = new MatTableDataSource([]);

    this.dataTypeOptions = [
      { value: 'string', text: 'variables-editor-row.selects.data_type.options.string', translate: true },
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'int', text: 'variables-editor-row.selects.data_type.options.int', translate: true }
        : null,
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'long', text: 'variables-editor-row.selects.data_type.options.long', translate: true }
        : null,
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'float', text: 'variables-editor-row.selects.data_type.options.float', translate: true }
        : null,
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'double', text: 'variables-editor-row.selects.data_type.options.double', translate: true }
        : null,
      { value: 'binary', text: 'variables-editor-row.selects.data_type.options.binary', translate: true },
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'boolean', text: 'variables-editor-row.selects.data_type.options.boolean', translate: true }
        : null,
      !this.settings.allowToChangeDataTypeToStringBinaryOnly
        ? { value: 'datetime', text: 'variables-editor-row.selects.data_type.options.datetime', translate: true }
        : null,
      this.settings.allowComplexDataTypes
        ? { value: 'json', text: 'variables-editor-row.selects.data_type.options.json', translate: true }
        : null,
      this.settings.allowComplexDataTypes
        ? { value: 'array', text: 'variables-editor-row.selects.data_type.options.array', translate: true }
        : null,
    ].filter(Boolean);

    this.generateColumns();
    this.validateFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings && changes.settings.currentValue) {
      this.generateColumns();
    }

    if (changes.fields && changes.fields.currentValue && this.dataSource) {
      (this.predefinedFields || []).forEach((field) => {
        if (!this.fields.find((item) => item.name === field.name)) {
          this.fields = [...this.fields, field];
        }
      });
      if (this.fields.length) {
        this.fieldsMap = this.hideTabs ? { Schema: this.fields } : groupBy(this.fields, 'category');
        this.tabs = this.hideTabs
          ? [{ name: 'Schema', active: true }]
          : Object.keys(this.fieldsMap).map((name) => ({ active: false, name }));

        this.selectTab(this.tabs[0].name);
        this.validateFields();
        this.fieldsMap = this.hideTabs ? { Schema: this.fields } : groupBy(this.fields, 'category');
        this.dataSource.data = this.currentFields;
      } else {
        this.fieldsMap = this.hideTabs ? { Schema: this.fields } : groupBy(this.fields, 'category');
        this.dataSource.data = [];
      }

      this.generateColumns();
    }

    if (changes.predefinedFields) {
      changes.predefinedFields.currentValue.forEach((field) => {
        if (!this.fields.find((item) => item.name === field.name)) {
          this.fields = [...this.fields, field];
        }
      });
    }
  }

  generateColumns() {
    this.displayedColumns = [
      this.settings.showFieldNumber ? 'row' : null,
      this.settings.allowToSortSelectedFields ? 'sort' : null,
      this.settings.showSourceDesignatorInSelectedFields ? 'name' : null,
      this.settings.showAlias ? 'alias' : null,
      'data_type',
      this.settings.allowToAddFieldBetweenFields && this.id === 'selected-fields' ? 'add_between' : null,
      this.id === 'selected-fields' &&
      (this.settings.allowToRemoveSelectedFields ||
        this.settings.hasEditableFields ||
        this.fields.some((field) => field.editable))
        ? 'remove'
        : null,
      this.id === 'available-fields' &&
      (this.settings.add || this.settings.hasEditableFields || this.fields.some((field) => field.predefined))
        ? 'add'
        : null,
    ].filter(Boolean);
  }

  selectTab(tabName: string, updateData = true) {
    this.tabs = this.tabs.map((tab) => ({ ...tab, active: tab.name === tabName }));
    if (updateData) {
      this.dataSource.data = this.fieldsMap[tabName];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackByName(index: number, item: ConnectionSchemaField) {
    return item.name;
  }

  onAliasChange(alias: string, index: number) {
    if (this.fields.length === 0) {
      return;
    }
    let realIndex = index;

    if (this.fields[0]?.id) {
      realIndex = this.fields.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    }

    if (!this.fields[realIndex] || this.fields[realIndex].alias === alias) {
      return;
    }
    const newFields = this.fields.map((item, itemIndex) => (itemIndex === realIndex ? { ...item, alias } : item));
    this.updateFields.emit(newFields);
  }

  onNameChange(name: string, index: number) {
    if (this.fields.length === 0) {
      return;
    }

    let realIndex = index;

    if (this.fields[0]?.id) {
      realIndex = this.fields.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    }

    if (!this.fields[realIndex] || this.fields[realIndex].name === name) {
      return;
    }
    const newFields = this.fields.map((item, itemIndex) => (itemIndex === realIndex ? { ...item, name } : item));
    this.updateFields.emit(newFields);
  }

  onDataTypeChange(data_type: string, index: number) {
    if (this.fields.length === 0) {
      return;
    }
    let realIndex = index;

    if (this.fields[0]?.id) {
      realIndex = this.fields.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    }

    if (!this.fields[realIndex] || this.fields[realIndex].data_type === data_type) {
      return;
    }
    const newFields = this.fields.map((item, itemIndex) => (itemIndex === realIndex ? { ...item, data_type } : item));
    this.updateFields.emit(newFields);
  }

  addFieldItem(index: number) {
    let realIndex = index;

    if (this.fields[0]?.id) {
      realIndex = this.currentFields.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    }

    this.addField.emit({ field: this.currentFields[realIndex], index: realIndex + 1 });
  }

  removeFieldItem(index: number) {
    let realIndex = index;

    if (this.fields[0]?.id) {
      realIndex = this.currentFields.findIndex((item) => item.id === this.dataSource.filteredData[index].id);
    }

    this.removeField.emit(this.currentFields[realIndex]);
  }

  addToEndClick() {
    this.addFieldToEnd.emit();
    this.dataSource.paginator.lastPage();
  }

  getRowState(index: number): any {
    if (this.dataSource && this.dataSource.paginator) {
      let realIndex = index + this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize;

      if (this.fields[0]?.id) {
        realIndex = this.currentFields.findIndex((item) => item.id === this.dataSource.filteredData[realIndex].id);
      }

      const field = this.currentFields[realIndex];

      if (field && this.selectedFields && this.selectedFields.length) {
        return {
          selected: !!this.selectedFields.find((item) => item.name === field.name),
          error: !!this.errors.find(({ index: errorIndex }) => index === errorIndex),
        };
      }

      return {
        error: !!this.errors.find(({ index: errorIndex }) => realIndex === errorIndex),
      };
    }

    return {};
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filterPredicate = filterPredicate;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  validateFields() {
    const uniqueAliases = {};
    const uniqueNames = {};
    let prop = 'alias';
    if (this.id === 'available-fields') {
      return;
    }

    this.errors = this.fields
      .map((field, index) => {
        let error = '';
        if (uniqueAliases[field.alias]) {
          error = `Field alias '${field.alias}' - must be unique`;
        } else {
          uniqueAliases[field.alias] = field.alias;
        }
        if (field.alias === '') {
          error = "Field alias can't be empty";
        }
        if (field.alias !== '' && !REGEXES.FIELD_NAME_FORMAT.test(field.alias)) {
          error = `Field alias '${field.alias}' - contains illegal characters`;
        }
        if (field.alias !== '' && REGEXES.RESERVED_KEYWORDS.test(field.alias)) {
          error = `Field alias '${field.alias}' - is an Xplenty reserved keyword`;
        }
        if (!field.name) {
          prop = 'name';
          error = 'Field name is required';
        }
        if (uniqueNames[field.name] && !this.ignoreFieldNameValidation) {
          error = 'Field name must be unique';
          prop = 'name';
        } else {
          uniqueNames[field.name] = field.name;
        }

        return {
          message: error,
          index,
          prop,
        };
      })
      .filter((error) => !!error.message);

    this.updateValidation.emit(!this.errors.length);
  }

  focusError(error: ErrorType) {
    this.focusOnInput(error.index, error.prop);
  }

  focusOnInput(matchedIndex: number, prop: string) {
    this.dataSource.paginator.pageIndex = Math.floor(matchedIndex / this.dataSource.paginator.pageSize);

    this.fields = this.fields.map((item, index) => ({ ...item, focusedProp: index === matchedIndex && prop }));
    this.fieldsMap = groupBy(this.fields, 'category');
    this.dataSource.data = this.currentFields;
    if (this.tabs.length > 1) {
      this.selectTab(this.fields.find((item, index) => index === matchedIndex).category);
    }
  }

  get activeTabName(): string {
    return this.tabs.find((item) => item.active)?.name || '';
  }

  get currentFields(): ConnectionSchemaField[] {
    return this.fieldsMap[this.activeTabName] || this.fields;
  }

  dropTable(event: CdkDragDrop<MatTableDataSource<any>, any>) {
    const prevIndex = this.fields.findIndex((d) => d === event.item.data);
    const toIndex = event.currentIndex + this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize;
    const fields = [...this.fields];
    moveItemInArray(fields, prevIndex, toIndex);
    this.fieldsMap = groupBy(fields, 'category');
    this.dataSource.data = this.currentFields;
    this.updateFields.emit(fields);
  }

  ngOnDestroy() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.dataSource.disconnect();
  }
}
