import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { DESIGNER_SETTINGS } from '../../../constants/designer_settings';
import { FlowType, Package } from '../../../packages/package.models';
import { selectPackagesErrors, selectPackagesIsSubmittingFlag } from '../../../packages/store/packages.selectors';
import { BaseForm, BaseFormInterface } from '../../base/base-form.component';
import { NotifyService } from '../../services/notify.service';
import { PackagesResource } from '../../../packages/resources/packages.resource';
import { closePackagesModal, savePackage, updatePackage } from '../../../packages/store/packages.actions';
import { updatePackage as updatePackageInPackageDesigner } from '../../../package-designer/store/package.actions';

interface PackageTemplateOption {
  flow_type?: FlowType;
  value: string | number;
  text: string;
  translate?: boolean;
}

@Component({
  selector: 'package-form',
  template: `
    <div class="package-form">
      <div class="package-form-body">
        <xp-form-validation [type]="item.id ? 'Package' : 'NewPackage'" [name]="formName">
          <form name="packageForm" #form="ngForm" class="modal-form-container">
            <div class="alert alert-warning" *ngIf="apiErrorMessage">
              <button type="button" class="close" (click)="apiErrorMessage = null">
                <span aria-hidden="true">&times;</span>
              </button>
              <span [innerHTML]="apiErrorMessage"></span>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <xp-name-description-editor
                    [item]="item"
                    [optionalName]="!item.id"
                    [namePlaceholder]="item.id ? 'Please fill the name' : 'package.form.new.placeholders.name'"
                  ></xp-name-description-editor>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!item.id">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="flow-types">{{ 'package.form.new.labels.flow_type' | translate }}</label>
                  <xp-select
                    id="flow-types"
                    name="flow_type"
                    class="form-control xp-select"
                    [value]="item.flow_type"
                    [options]="flowTypeOptions"
                    (valueChange)="onFlowTypeChange($event)"
                    [preventEmpty]="true"
                  >
                  </xp-select>
                  <small>{{ 'package.form.new.hints.flow_type' | translate }}</small>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!item.id">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="package-templates">{{ 'package.form.new.labels.template' | translate }}</label>
                  <xp-select
                    id="package-templates"
                    name="package_template_id"
                    [isSearchEnabled]="packageTemplateOptions.length > 1"
                    class="form-control xp-select"
                    [value]="item.package_template_id"
                    [options]="packageTemplateOptions"
                    (valueChange)="onTemplateIdChange($event)"
                    emptyPlaceholder="No template found"
                    [preventEmpty]="true"
                  >
                  </xp-select>
                  <small>{{ 'package.form.new.hints.template' | translate }}</small>
                </div>
              </div>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="package-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-package" size="L"></common-icon>
          <h3 class="modal-title">
            {{
              (item.id ? 'package.controller.modals.edit_name.actions.title' : 'package.generic-object.title')
                | translate
            }}
          </h3>
        </div>

        <xp-submit-button
          (click)="savePackage(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'package.form.buttons.create' | translate"
          [updateText]="item.id && 'package.form.buttons.update' | translate"
          [isFormValid]="item.id ? !!item.name : form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class PackageFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<Package> = {};
  @Input() isPackageDesigner = false;
  @ViewChild('form') form: NgForm;
  formName = 'packageForm';
  successMessageText = 'package.form.success_message';
  isSubmitting$ = this.store.select(selectPackagesIsSubmittingFlag);
  errors$ = this.store.select(selectPackagesErrors);
  errorTexts = [];
  apiErrorMessage = null;

  flowTypeOptions = [
    {
      value: 'dataflow',
      text: 'package.form.new.labels.dataflow',
      translate: true,
    },
    {
      value: 'workflow',
      text: 'package.form.new.labels.workflow',
      translate: true,
    },
  ];
  packageTemplateOptions: PackageTemplateOption[] = [];
  packageTemplates: PackageTemplateOption[] = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private packagesResource: PackagesResource,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.packagesResource.templates().subscribe({
      next: (resPackageTemplates) => {
        this.apiErrorMessage = '';
        this.packageTemplates = [
          { value: 'blank', text: 'package.form.new.labels.blank_template', translate: true },
          ...resPackageTemplates.map(({ id, name, flow_type }) => ({ value: id, text: name, flow_type })),
        ];
        this.filterTemplates(this.item.flow_type);
      },
      error: (error) => {
        if (error.message) {
          this.apiErrorMessage = error.message;
        }
        this.notify.error(`An error occurred while calling templates endpoint.`);
      },
    });
  }

  filterTemplates(flowType: FlowType) {
    this.packageTemplateOptions = this.packageTemplates.filter(
      ({ flow_type, value }) => flow_type === flowType || value === 'blank',
    );
  }

  onFlowTypeChange(flowType: FlowType) {
    this.item.flow_type = flowType;
    this.filterTemplates(flowType);
    this.item.package_template_id = 'blank';
  }

  onTemplateIdChange(templateId: string) {
    this.item.package_template_id = templateId;
  }

  savePackage(packageItem: Partial<Package>) {
    const newPackage: any = { ...packageItem };

    if (newPackage.package_template_id === 'blank') delete newPackage.package_template_id;
    newPackage.flow_version = DESIGNER_SETTINGS.FLOW_VERSION;

    if (this.route.snapshot.params.workspace_id) {
      newPackage.workspace_id = this.route.snapshot.params.workspace_id;
    }

    if (this.isPackageDesigner) {
      this.store.dispatch(updatePackageInPackageDesigner({ packageItem: newPackage }));
    } else if (packageItem.id) {
      this.store.dispatch(updatePackage({ packageItem: newPackage, params: {}, packageId: packageItem.id }));
    } else {
      this.store.dispatch(savePackage({ packageItem: newPackage, redirect: true }));
    }
    this.store.dispatch(closePackagesModal());
  }
}
