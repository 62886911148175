import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jwt_decode from 'jwt-decode';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import {
  selectHooksIsSubmittingFlag,
  selectHookTypes,
  selectSelectedHookType,
} from '../../store/hooks/hooks.selectors';
import { loadHookImage } from '../../helpers/load-hook-image.helper';
import { Hook, HookType, HookTypesEnum } from '../../hook.models';
import { saveHook, selectHookType, updateHook } from '../../store/hooks/hooks.actions';
import { Member } from '../../members.models';
import { ConnectionAuthenticationService } from '../../../connections/services/connection-authentication.service';
import { AuthService } from '../../../common/services/auth.service';
import { selectAccountIdNumber } from '../../../account/store/account.selectors';
import { first } from 'rxjs/operators';

@Component({
  selector: 'hook-form',
  template: `
    <div class="hook-form">
      <div *ngIf="!(currentHookType$ | async)">
        <div class="hook-groups-list modal-form-container">
          <div class="hooks-group-item">
            <strong>{{ 'hook.form.title' | translate }}</strong>
            <div class="hook-item" (click)="setCurrentHookType(hookType)" *ngFor="let hookType of hookTypes$ | async">
              <img [alt]="hookType.name" [src]="loadHookImage(hookType.type)" />
              <span>{{ hookType.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentHookType$ | async as currentHookType" class="hook-form-body">
        <div class="modal-form-container">
          <div class="hook-type-header">
            <img class="hook-logo" [src]="loadHookImage(currentHookType.type)" [alt]="currentHookType.name" />
            <div class="hook-type-description">
              <strong>{{ currentHookType.name }}</strong>
              <p>{{ currentHookType.description }}</p>
            </div>
          </div>
          <div class="hook-form-body" [ngSwitch]="currentHookType.type">
            <hook-form-email
              [(item)]="item"
              (formValidity)="onFormValidityChange($event)"
              *ngSwitchCase="hookTypesEnum.email"
            ></hook-form-email>
            <hook-form-web
              [(item)]="item"
              (formValidity)="onFormValidityChange($event)"
              *ngSwitchCase="hookTypesEnum.web"
            ></hook-form-web>
            <hook-form-slack
              [(item)]="item"
              [slackData]="slackData"
              (formValidity)="onFormValidityChange($event)"
              *ngSwitchCase="hookTypesEnum.slack"
            ></hook-form-slack>
            <hook-form-pagerduty
              [(item)]="item"
              (formValidity)="onFormValidityChange($event)"
              *ngSwitchCase="hookTypesEnum.pagerduty"
            ></hook-form-pagerduty>
          </div>
        </div>
      </div>
      <div class="hook-form-footer modal-footer modal-panel-footer" *ngIf="currentHookType$ | async">
        <div class="modal-title-container">
          <h3 class="modal-title">
            <span *ngIf="item.id">{{ 'hook.form.titles.edit' | translate }}</span>
            <span *ngIf="!item.id">{{ 'hook.form.titles.create' | translate }}</span>
          </h3>
        </div>

        <xp-submit-button
          (click)="submitHook(item)"
          classNames="btn-lg btn-success pull-right"
          [createText]="!item.id && 'hook.form.buttons.create' | translate"
          [updateText]="item.id && 'hook.form.buttons.update' | translate"
          [isFormValid]="isFormValid"
          [isFormSubmitting]="isSubmitting$ | async"
          *ngxPermissionsOnly="'createHook'"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class HookFormComponent {
  @Input() item: Partial<Hook> = {};
  isSubmitting$ = this.store.select(selectHooksIsSubmittingFlag);
  hookTypes$ = this.store.select(selectHookTypes);
  currentHookType$ = this.store.select(selectSelectedHookType);
  loadHookImage = loadHookImage;
  hookTypesEnum = HookTypesEnum;
  isFormValid = false;
  slackData: Partial<Hook>;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private connectionAuthenticationService: ConnectionAuthenticationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  submitHook(hook: Partial<Member>) {
    if (hook.id) {
      this.store.dispatch(updateHook({ hook: cloneDeep(hook) }));
    } else {
      this.store.dispatch(saveHook({ hook: cloneDeep(hook) }));
    }

    this.router.navigate(['./'], { relativeTo: this.route });
  }

  onFormValidityChange(isFormValid: boolean) {
    this.isFormValid = isFormValid;
  }

  setCurrentHookType(hookType: HookType): void {
    if (hookType.type === HookTypesEnum.slack) {
      this.store
        .select(selectAccountIdNumber)
        .pipe(first())
        .subscribe((accountId) => {
          this.connectionAuthenticationService
            .authenticate(hookType.type as any, {
              access_token: this.authService.getToken(),
              account_id: accountId,
            })
            .then((res) => {
              this.goToNewHook(hookType, jwt_decode(res.token));
            });
        });
    } else {
      this.goToNewHook(hookType);
    }
  }

  goToNewHook(hookType: HookType, hookItem?: Partial<Hook>): void {
    this.store.dispatch(selectHookType({ hookType: hookType.type }));

    if (hookItem) {
      this.item = { ...hookItem, active: true };
    } else {
      this.item.type = hookType.type;
    }

    this.router.navigate([`${hookType.type}/new`], { relativeTo: this.route });
  }
}
