import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { FunctionPickerType } from '../../../../constants/function_picker';
import { HUBSPOT_ASSOCIATIONS_TYPES } from '../../../../constants/hubspot_associations_types';

@Component({
  selector: 'xp-hubspot-association-type-picker',
  template: `
    <div class="type-picker">
      <xp-select
        *ngIf="typeCategory === 'HUBSPOT_DEFINED'"
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="types"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        placeholder="Select an association"
        style="width: 100%;"
      ></xp-select>
      <xp-input
        *ngIf="typeCategory === 'USER_DEFINED'"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        type="number"
        placeholder="Write a valid type ID (eg: 723)"
        name="type_picker"
      ></xp-input>
    </div>
  `,
  providers: [],
})
export class XpHubspotAssociationTypePickerComponent implements OnInit, OnChanges {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Input() componentType: FunctionPickerType;
  @Input() objectName: string;
  @Input() typeCategory: string;
  @Output() valueChange = new EventEmitter();
  types: SelectOption[] = [];
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objectName?.currentValue) {
      this.types = HUBSPOT_ASSOCIATIONS_TYPES[this.objectName.toLowerCase()] || [];
    }
  }

  onValueChange(value: ConditionOperator) {
    if (this.value === value || (!value && !this.value)) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.valueChange.emit(value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
