import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { FieldsCollectionValidationsService } from '../../../../common/services/fields-collection-validations.service';
import { ValidationsEnum } from '../../../../common/services/validations.enum';
import { ValidatorData } from '../../../../common/validators/helpers/validator-data.model';

@Component({
  selector: 'xp-field-picker',
  template: `
    <div class="field-picker" [ngClass]="state">
      <div class="editor-picker">
        <xp-select
          [value]="value"
          (valueChange)="onValueChange($event)"
          [options]="selectOptions"
          [tooltip]="tooltipValue"
          [state]="state"
          [disabled]="disable"
          (blur)="onInputBlur()"
          (focus)="onInputFocus()"
          [isSearchEnabled]="true"
          [preventEmpty]="preventEmpty"
          [placeholder]="placeholder"
          emptyPlaceholder="No field found"
        ></xp-select>
      </div>
    </div>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpFieldPickerComponent implements OnInit, OnChanges {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Input() disable: boolean;
  @Input() propName: string;
  @Input() fieldName: string;
  @Input() fields: any[];
  @Input() schema: any;
  @Input() isDuplicateError: boolean;
  @Input() isOutsideDuplicateError: boolean;
  @Input() outsideValidationMessage: string;
  @Input() preventEmpty = true;
  @Input() isSalesforceField = false;
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Output() fieldChange = new EventEmitter();
  validate: (...args: any) => ValidatorData;
  tooltipValue = '';
  state: string;
  selectOptions: SelectOption[];
  isFirstChange = true;

  constructor(
    private xpFieldsCollectionComponent: XpFieldsCollectionComponent,
    private fieldsCollectionValidationsService: FieldsCollectionValidationsService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.validate = this.fieldsCollectionValidationsService.getValidationFunction(ValidationsEnum.FIELD_PICKER);
    this.tooltipValue = this.value;

    if (this.tooltip) {
      this.tooltipValue = this.tooltip;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.validityFn(changes.value.currentValue);
    }

    if (changes.fields && changes.fields.currentValue) {
      this.selectOptions = (this.fields || []).map((field) => ({
        value: field.name,
        text: this.isSalesforceField && field.name.endsWith('__c') ? field.name + ' (Custom field)' : field.name,
      }));
    }

    if (!changes.value && (changes.isDuplicateError || changes.isOutsideDuplicateError)) {
      this.validityFn(this.value);
    }

    if (this.tooltip) {
      this.tooltipValue = this.tooltip;
    }
  }

  onValueChange(value: ConditionOperator) {
    if (this.value === value || (!value && !this.value)) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.value = value;
    this.fieldChange.emit(this.value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }

  validityFn(value: string): boolean {
    if (this.xpFieldsCollectionComponent && this.xpFieldsCollectionComponent.isRecordPristine(this.index)) {
      return false;
    }

    if (!this.validate) {
      this.validate = this.fieldsCollectionValidationsService.getValidationFunction(ValidationsEnum.FIELD_PICKER);
    }

    if (typeof this.validate === 'function') {
      const validityObject = this.validate(value, this.schema);
      this.setValidity(validityObject);
      return validityObject.valid;
    }

    return false;
  }

  setValidity(validityObject: Partial<ValidatorData>) {
    const isValid = validityObject.valid && !this.isDuplicateError && !this.isOutsideDuplicateError;

    const ignoreError = validityObject.message === 'Value can’t be empty';

    if (!isValid) {
      this.state = ignoreError ? 'editable' : 'error';
      if (this.isDuplicateError) {
        this.tooltipValue = this.translate.instant('fields-collection.errors.duplicate_field', {
          fieldName: this.fieldName || 'Alias',
        });
      } else if (this.isOutsideDuplicateError) {
        this.tooltipValue = this.outsideValidationMessage;
      } else {
        this.tooltipValue = validityObject.message;
      }
      if (ignoreError) {
        this.tooltipValue = this.value;
      }
    } else {
      this.state = 'editable';
      this.tooltipValue = this.value;
    }

    this.registerError(isValid, this.tooltipValue, ignoreError);
  }

  registerError(valid: boolean, message: string, hideMessage: boolean) {
    if (this.xpFieldsCollectionComponent) {
      if (!valid) {
        this.xpFieldsCollectionComponent.registerError(this.index, message, this.propName, hideMessage);
      } else {
        this.xpFieldsCollectionComponent.removeError(this.index, this.propName);
      }
    }
  }
}
