import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { XpFieldsCollectionComponent } from '../xp-fields-collection.component';
import { ConditionOperator } from '../../../../constants/conditions_operator_picker';
import { SelectOption } from '../../../../common/components/forms/xp-select.component';
import { FunctionPickerType } from '../../../../constants/function_picker';

@Component({
  selector: 'xp-hubspot-association-category-picker',
  template: `
    <div class="type-picker">
      <xp-select
        [value]="value"
        (valueChange)="onValueChange($event)"
        [options]="types"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        [preventEmpty]="true"
        style="width: 100%;"
      ></xp-select>
    </div>
  `,
  providers: [],
})
export class XpHubspotAssociationCategoryPickerComponent implements OnInit {
  @Input() value: ConditionOperator;
  @Input() index: number;
  @Input() componentType: FunctionPickerType;
  @Output() valueChange = new EventEmitter();
  types: SelectOption[] = [
    {
      text: 'HUBSPOT_DEFINED',
      value: 'HUBSPOT_DEFINED',
    },
    {
      text: 'USER_DEFINED',
      value: 'USER_DEFINED',
    },
  ];
  isFirstChange = true;

  constructor(private xpFieldsCollectionComponent: XpFieldsCollectionComponent) {}

  ngOnInit() {}

  onValueChange(value: ConditionOperator) {
    if (this.value === value || (!value && !this.value)) {
      return;
    }

    if (this.isFirstChange) {
      if (this.xpFieldsCollectionComponent) {
        this.xpFieldsCollectionComponent.setRecordPristineState(this.index, false);
      }
      this.isFirstChange = false;
    }

    this.valueChange.emit(value);
  }

  onInputFocus() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: true });
    }
  }

  onInputBlur() {
    if (this.xpFieldsCollectionComponent) {
      this.xpFieldsCollectionComponent.setRowState(this.index, { isActive: false });
    }
  }
}
