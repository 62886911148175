import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Terminal } from 'xterm';
import chalk, { Options } from 'chalk';
import { FitAddon } from 'xterm-addon-fit';
import { MatDialog } from '@angular/material/dialog';
import { parser } from '../../services/pig-parser';
import { ParserI } from '../../services/pig-parser.interface';
import { LabServicesResource } from '../resources/lab-services.resource';
import { XplentyConsoleShortcutsComponent } from './xplenty-console-shortcuts.component';
import { XplentyConsoleFunctionsComponent } from './xplenty-console-functions.component';
import { Subject, Subscription } from 'rxjs';

const options: Options = { level: 2 };
const forcedChalk = new chalk.Instance(options);

const ENTER = '\r';
const NEW_LINE = '\n';
const BACKSPACE = '\u007F';
const ARROW_UP = '\x1b[A';
const ARROW_DOWN = '\x1b[B';
const ARROW_RIGHT = '\x1b[C';
const ARROW_LEFT = '\x1b[D';
const TAB = '\t';
const SHIFT_TAB = '\x1b[Z';
const availableShortcuts = ['c', 'v', 'r'];

export let GLOBAL_TERM_REFERENCE: Terminal = null;

function delay(fn, ms) {
  let timer: any = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

@Component({
  selector: 'xplenty-console',
  template: `
    <div class="console-container" [ngClass]="{ 'full-size': fullSize }">
      <div class="browser-bar" *ngIf="!hideBar">
        <div class="actions">
          <div class="dot red"></div>
          <div class="dot yellow"></div>
          <div class="dot green"></div>
        </div>
      </div>
      <div id="terminal"></div>
      <a *ngIf="!hideShortcutsIcons" rel="tooltip" (click)="openShortcutsDialog()" class="btn btn-small shortcuts-btn"
        ><i class="icon fa fa-keyboard-o"></i
      ></a>
      <a *ngIf="!hideShortcutsIcons" rel="tooltip" (click)="openFunctionsDialog()" class="btn btn-small functions-btn"
        ><i class="icon fa fa-code"></i
      ></a>
    </div>
  `,
})
export class XplentyConsoleComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() hideBar = false;
  @Input() fullSize = true;
  @Input() hideShortcutsIcons = false;
  @Input() hideWelcomeText = false;
  @Input() inputText = '';
  @Input() isFocused = false;
  @Input() isHidden = false;
  @Input() resizeWindowSubject: Subject<void>;
  private isDialogOpen = false;
  private isTermInitialized = false;
  private terminalValue = [''];
  private term: Terminal;
  private commandsCache: Array<string[]> = [['']];
  private commandCacheCurrentIndex = 0;
  private cursorPosition = 0;
  private isCtrlKeyPressed = false;
  private isShiftKeyPressed = false;
  private isAltKeyPressed = false;
  private keyDownListenerRef: (event: KeyboardEvent) => void = null;
  private keyUpListenerRef: (event: KeyboardEvent) => void = null;
  private resizeListenerRef: () => void = null;
  private currentRow = 0;
  private fitAddon: FitAddon;

  resizeWindowSubjectSubscription: Subscription;

  constructor(
    private LabServices: LabServicesResource,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.term = new Terminal({
      cursorBlink: true,
      scrollback: 1000,
      tabStopWidth: 4,
      cursorStyle: 'bar',
      fontSize: 16,
      lineHeight: 1,
    });
    GLOBAL_TERM_REFERENCE = this.term;
    this.fitAddon = new FitAddon();
    this.term.loadAddon(this.fitAddon);
    this.term.open(document.getElementById('terminal')!);
    this.fitAddon.fit();

    this.runFakeTerminal();
    this.term.focus();
    this.keyDownListenerRef = this.handleKeyDown.bind(this);
    this.keyUpListenerRef = this.handleKeyUp.bind(this);
    this.resizeListenerRef = this.handleResize.bind(this);

    document.addEventListener('keydown', this.keyDownListenerRef);
    document.addEventListener('keyup', this.keyUpListenerRef);
    window.addEventListener('resize', this.resizeListenerRef);

    if (this.resizeWindowSubject) {
      this.resizeWindowSubjectSubscription = this.resizeWindowSubject.subscribe({
        next: () => {
          this.handleResize();
        },
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputText && changes.inputText.currentValue && this.term) {
      this.term.write(changes.inputText.currentValue);
      this.term.focus();
    }

    if (changes.isFocused && changes.isFocused.currentValue && this.term) {
      setTimeout(() => {
        this.term.focus();
      });
    }
  }

  ngOnDestroy() {
    GLOBAL_TERM_REFERENCE = null;
    document.removeEventListener('keydown', this.keyDownListenerRef);
    document.removeEventListener('keyup', this.keyUpListenerRef);
    window.removeEventListener('resize', this.resizeListenerRef);
    GLOBAL_TERM_REFERENCE = null;

    if (this.resizeWindowSubjectSubscription) {
      this.resizeWindowSubjectSubscription.unsubscribe();
    }
  }

  public openShortcutsDialog() {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(XplentyConsoleShortcutsComponent, {
      data: {},
      position: {
        top: '150px',
      },
      maxWidth: '500px',
      panelClass: 'shortcuts-dialog',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isDialogOpen = false;
      this.term.focus();
    });
  }

  public openFunctionsDialog() {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(XplentyConsoleFunctionsComponent, {
      data: {},
      position: {
        top: '150px',
      },
      maxWidth: '500px',
      panelClass: 'functions-dialog',
    });

    dialogRef.afterClosed().subscribe(async (functionExample: string) => {
      if (functionExample) {
        const functions = functionExample.split(NEW_LINE);
        // eslint-disable-next-line no-restricted-syntax
        for (const example of functions) {
          this.setTermInput(example);
          // eslint-disable-next-line no-await-in-loop
          await this.handleEnterKey(ENTER);
        }
      }
      this.isDialogOpen = false;
      this.term.focus();
    });
  }

  private get lastTerminalValue(): string {
    return this.terminalValue[this.terminalValue.length - 1];
  }

  private runFakeTerminal(): void {
    if (this.isTermInitialized) {
      return;
    }

    this.isTermInitialized = true;

    this.term.writeln('Welcome to X-Console');
    if (!this.hideWelcomeText) {
      this.term.writeln('Type or paste your expressions here to evaluate them and see the results.');
      this.term.writeln('You can use either literal values or variables as function arguments. For example: ');
      this.term.writeln('');
      this.prompt();
      this.term.writeln("name = 'President John Smith'");
      this.addCommandToCache(["name = 'President John Smith'"]);
      this.prompt();
      this.term.writeln("REPLACE(name,'President','Ex-President')");
      this.addCommandToCache(["REPLACE(name,'President','Ex-President')"]);
    } else {
      this.addCommandToCache(["name = 'President John Smith'"]);
    }
    this.prompt();

    this.term.onData(
      delay((e: string) => {
        const code = e.charCodeAt(0);
        switch (e) {
          case ENTER: // Enter
            this.handleEnterKey(e);
            break;
          case BACKSPACE: // Backspace (DEL)
            this.handleBackspaceKey();
            break;
          case ARROW_UP: // Arrow up
            this.handleArrowUpKey();
            break;
          case ARROW_DOWN: // Arrow down
            this.handleArrowDownKey();
            break;
          case ARROW_RIGHT: // Arrow right
            this.handleArrowRightKey(e);
            break;
          case ARROW_LEFT: // Arrow left
            this.handleArrowLeftKey(e);
            break;
          case TAB: // Tab
            this.handleIndent();
            break;
          case SHIFT_TAB: // Shift + Tab
            this.handleRemoveIndent();
            break;
          default:
            if ((code > 31 && code < 127) || code > 160) {
              this.setTermInput(e);
            }
        }
      }, 20),
    );

    this.term.attachCustomKeyEventHandler((event) => {
      if (event.ctrlKey && event.code === 'KeyV' && event.type === 'keydown') {
        return false;
      }

      if (event.ctrlKey && event.code === 'KeyC' && event.type === 'keydown') {
        return false;
      }

      return true;
    });
  }

  private addCommandToCache(command: string[]) {
    const [head, ...tail] = this.commandsCache;
    this.commandsCache = [head, [...command], ...tail];
  }

  private prompt(): void {
    this.term.write('\r$ ');
  }

  private typeMultipleTimes(data: string, times: number): void {
    for (let i = 0; i < times; i += 1) {
      this.term.write(data);
    }
  }

  private resetCursorPositionRight() {
    const cursorDiff = this.terminalValue[this.currentRow].length - this.cursorPosition;
    this.typeMultipleTimes(ARROW_RIGHT, cursorDiff);
  }

  private resetCursorPositionLeft() {
    const cursorDiff = this.cursorPosition;
    this.typeMultipleTimes(ARROW_LEFT, Math.abs(cursorDiff));
  }

  private setPreviousCursorPosition() {
    const rowsDiff = this.terminalValue.length - 1 - this.currentRow;
    if (rowsDiff > 0) {
      this.typeMultipleTimes(ARROW_UP, rowsDiff);
    }

    if (this.currentRow !== this.terminalValue.length - 1 && this.terminalValue[this.currentRow]) {
      const lengthDiff = this.terminalValue[this.currentRow].length - this.lastTerminalValue.length;
      if (lengthDiff < 0) {
        this.typeMultipleTimes(ARROW_LEFT, Math.abs(lengthDiff));
      } else {
        this.typeMultipleTimes(ARROW_RIGHT, lengthDiff);
      }
    }

    const cursorDiff = ((this.terminalValue[this.currentRow] || {}).length || 0) - this.cursorPosition;
    if (cursorDiff < 0) {
      this.typeMultipleTimes(ARROW_RIGHT, Math.abs(cursorDiff + 1));
    } else {
      this.typeMultipleTimes(ARROW_LEFT, cursorDiff);
    }
  }

  private goToTheEnd(): number {
    const rowsDiff = this.terminalValue.length - 1 - this.currentRow;
    let rightBuffer = 0;
    if (rowsDiff > 0) {
      const cursorPositionFromRight = this.terminalValue[this.currentRow].length - this.cursorPosition;
      const charsDiffInRows =
        this.terminalValue[this.currentRow].length - this.lastTerminalValue.length - cursorPositionFromRight;
      if (charsDiffInRows > 0) {
        this.typeMultipleTimes(ARROW_LEFT, charsDiffInRows);
      } else {
        rightBuffer = Math.abs(charsDiffInRows);
        this.typeMultipleTimes(ARROW_RIGHT, rightBuffer);
      }

      this.typeMultipleTimes(ARROW_DOWN, rowsDiff);
    }

    return rightBuffer;
  }

  private clearCurrentCommand(): void {
    const rightBuffer = this.goToTheEnd();

    for (let i = this.terminalValue.length - 1; i >= 0; i -= 1) {
      const row = this.terminalValue[i];
      const bufferX = this.term.buffer.active.cursorX;
      const isLastRow = i === this.terminalValue.length - 1;
      let goToRight = isLastRow ? row.length + 2 - bufferX : row.length;
      if (isLastRow && rightBuffer) {
        goToRight = 0;
      }

      this.typeMultipleTimes(ARROW_RIGHT, goToRight);
      this.typeMultipleTimes('\b \b', row.length);

      if (i !== 0) {
        this.term.write(ARROW_UP);
      }
    }
  }

  private handleResize(): void {
    this.clearCurrentCommand();
    this.fitAddon.fit();
    this.splitTerminalValue();
    this.writeTerminalValue();
    this.currentRow += Math.floor(this.cursorPosition / this.cols);
    this.cursorPosition %= this.cols;
    this.setPreviousCursorPosition();
  }

  private handleKeyUp(e: KeyboardEvent): boolean {
    if (this.isDialogOpen || this.isHidden) {
      return true;
    }

    e.preventDefault();
    this.isCtrlKeyPressed = e.ctrlKey || e.metaKey;
    this.isShiftKeyPressed = e.shiftKey;

    const code = e.key.charCodeAt(0);

    if (code >= 65 && code <= 90 && e.key.length === 1 && !this.isShiftKeyPressed) {
      this.setTermInput(e.key);
    }
    return false;
  }

  private handleKeyDown(e: KeyboardEvent): boolean {
    if (this.isDialogOpen || this.isHidden) {
      return true;
    }

    this.isCtrlKeyPressed = e.ctrlKey || e.metaKey;
    this.isShiftKeyPressed = e.shiftKey;
    this.isAltKeyPressed = e.altKey;

    if (this.isCtrlKeyPressed && availableShortcuts.includes(e.key)) {
      return true;
    }

    if (!this.isShiftKeyPressed && e.key !== 'CapsLock' && !this.isAltKeyPressed) {
      e.preventDefault();
    }

    if (this.isCtrlKeyPressed && e.key === 's') {
      this.openShortcutsDialog();
    }

    if (this.isCtrlKeyPressed && e.key === 'g') {
      this.openFunctionsDialog();
    }

    if (this.isCtrlKeyPressed && e.key === 'l') {
      this.term.clear();
    }

    if (this.isCtrlKeyPressed && e.key === 'z') {
      this.cancelCommand();
    }

    if (this.isCtrlKeyPressed && e.key === 'a') {
      this.moveCursorToBegin();
    }

    if (this.isCtrlKeyPressed && e.key === 'e') {
      this.moveCursorToEnd();
    }

    if (this.isCtrlKeyPressed && e.key === 'ArrowUp') {
      this.moveCursorUp();
    }

    if (this.isCtrlKeyPressed && e.key === 'ArrowDown') {
      this.moveCursorDown();
      this.moveCursorToEnd();
    }

    if (e.key === ' ') {
      this.setTermInput(e.key);
    }

    return false;
  }

  private moveCursorDown() {
    if (this.currentRow < this.terminalValue.length - 1) {
      this.term.write(ARROW_DOWN);
      this.currentRow += 1;
    }
  }

  private moveCursorUp() {
    if (this.currentRow > 0) {
      this.term.write(ARROW_UP);
      this.currentRow -= 1;
    }
  }

  private moveCursorToBegin(): void {
    if (this.cursorPosition > 0) {
      this.resetCursorPositionLeft();
      this.cursorPosition = 0;
    }
  }

  private moveCursorToEnd(): void {
    if (this.cursorPosition < this.terminalValue[this.currentRow].length) {
      this.resetCursorPositionRight();
      this.cursorPosition = this.terminalValue[this.currentRow].length;
    } else {
      const cursorDiff = this.cursorPosition - this.terminalValue[this.currentRow].length;
      this.typeMultipleTimes(ARROW_LEFT, Math.abs(cursorDiff));
      this.cursorPosition = this.terminalValue[this.currentRow].length;
    }
  }

  private cancelCommand(): void {
    if (
      this.terminalValue[0] !== '' &&
      this.commandsCache[1] &&
      this.commandsCache[1].join() !== this.terminalValue.join()
    ) {
      this.addCommandToCache(this.terminalValue);
    }
    this.cursorPosition = 0;
    this.terminalValue = [''];
    this.currentRow = 0;
    this.prompt();
  }

  private get cols(): number {
    return this.term.cols - 4;
  }

  private deleteChar() {
    this.clearCurrentCommand();
    const terminalValue = this.terminalValue[this.currentRow];
    this.terminalValue[this.currentRow] =
      terminalValue.substring(0, this.cursorPosition - 1) + terminalValue.substring(this.cursorPosition);

    if (terminalValue.length === this.cols) {
      this.splitTerminalValue();
    }

    this.cursorPosition -= 1;
    this.writeTerminalValue();
    this.setPreviousCursorPosition();
  }

  private handleBackspaceKey(): void {
    if (this.terminalValue[this.currentRow] && this.cursorPosition > 0) {
      this.deleteChar();
    } else {
      if (this.currentRow > 0) {
        setTimeout(() => {
          this.moveCursorUp();
          this.moveCursorToEnd();
        }, 20);
        setTimeout(() => {
          this.deleteChar();
        }, 40);
      }
    }
  }

  private typeInTerminal(text: string[], { saveCursorPosition = false } = {}): void {
    this.clearCurrentCommand();
    this.terminalValue = text;
    this.writeTerminalValue();

    if (saveCursorPosition) {
      return;
    }

    this.cursorPosition = text[text.length - 1].length;
    this.currentRow = text.length - 1;
  }

  private handleArrowUpKey(): void {
    if (this.commandCacheCurrentIndex < this.commandsCache.length - 1) {
      this.commandCacheCurrentIndex += 1;

      const command = [...this.commandsCache[this.commandCacheCurrentIndex]];
      this.typeInTerminal(command);
    }
  }

  private handleArrowDownKey(): void {
    if (this.commandCacheCurrentIndex > 0) {
      this.commandCacheCurrentIndex -= 1;
      const command = [...this.commandsCache[this.commandCacheCurrentIndex]];
      this.typeInTerminal(command);
    }
  }

  private handleArrowRightKey(e: string): void {
    if (this.cursorPosition < this.terminalValue[this.currentRow].length) {
      this.cursorPosition += 1;
      this.term.write(e);
    } else {
      if (this.terminalValue[this.currentRow + 1]) {
        this.moveCursorDown();
        this.moveCursorToBegin();
      }
    }
  }

  private handleArrowLeftKey(e: string): void {
    if (this.cursorPosition > 0) {
      this.cursorPosition -= 1;
      this.term.write(e);
    } else {
      if (this.currentRow > 0) {
        this.moveCursorUp();
        this.moveCursorToEnd();
      }
    }
  }

  private handleIndent(): void {
    const newValue = this.terminalValue.slice();
    newValue[this.currentRow] = `    ${this.terminalValue[this.currentRow]}`;
    this.typeInTerminal(newValue, { saveCursorPosition: true });
    this.cursorPosition += 4;
    this.setPreviousCursorPosition();
  }

  private handleRemoveIndent(): void {
    const terminalValue = this.terminalValue[this.currentRow];
    if (terminalValue.substring(0, 3) === '   ') {
      const newValue = this.terminalValue.slice();
      newValue[this.currentRow] = terminalValue.slice(4, this.terminalValue.length);
      this.typeInTerminal(newValue, { saveCursorPosition: true });
      this.cursorPosition -= 4;
      this.setPreviousCursorPosition();
    }
  }

  private handleNextLine(): void {
    this.currentRow += 1;
    this.term.write('\r\n  ');

    if (!this.terminalValue[this.currentRow]) {
      this.terminalValue.push('');
    }
    this.cursorPosition = 0;
  }

  private async handleEnterKey(e: string): Promise<null> {
    if (this.isShiftKeyPressed) {
      this.handleNextLine();
      return;
    }

    if (this.terminalValue.join() === '') {
      this.term.write(NEW_LINE);
      this.prompt();
      return;
    }

    this.goToTheEnd();

    this.commandCacheCurrentIndex = 0;

    if (
      this.terminalValue[0] !== '' &&
      this.commandsCache[1] &&
      this.commandsCache[1].join() !== this.terminalValue.join()
    ) {
      this.addCommandToCache(this.terminalValue);
    }
    this.cursorPosition = 0;
    this.term.writeln('');

    try {
      const parserResult = (parser as ParserI).parse(this.terminalValue.join(''));

      const response = await this.LabServices.evaluate(parserResult);

      const { result, error } = response;

      if (!error) {
        if (response.callback !== 'undefined') {
          // Call the callback function with the result
          // eslint-disable-next-line
          new Function(response.callback)(response.result_object);
        }
        this.term.write(forcedChalk.yellowBright(`==> ${result}\n`));
      } else if (typeof error === 'string') {
        const errorText = error.split(NEW_LINE);
        errorText.forEach((item: string) => {
          this.term.writeln(forcedChalk.redBright(item));
        });
      } else {
        this.term.writeln(forcedChalk.redBright(`ERROR: Network error occurred.`));
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorText = error.toString().split(NEW_LINE);
        errorText[0] = `ERROR: ${errorText[0]}`;
        errorText.forEach((item) => {
          this.term.writeln(forcedChalk.redBright(item));
        });
      }
    }

    this.prompt();

    if (e !== '\r') {
      this.terminalValue[this.currentRow] = e;
    } else {
      this.terminalValue = [''];
    }
    this.currentRow = 0;

    return null;
  }

  private splitTerminalValue() {
    const terminalValueSplitted = this.terminalValue.join('').match(new RegExp(`.{1,${this.cols}}`, 'g')) || [''];
    this.terminalValue = [...terminalValueSplitted];
  }

  private setTermInput(e: string): void {
    this.clearCurrentCommand();
    const terminalValue = this.terminalValue[this.currentRow] || '';

    const futureRowLength = e.length + terminalValue.length;

    if (e.includes(NEW_LINE) || e.includes(ENTER)) {
      this.terminalValue = e.includes(NEW_LINE) ? e.split(NEW_LINE) : e.split(ENTER);
      this.terminalValue = this.terminalValue.map((line) => `${line} `);
      this.writeTerminalValue();
      this.currentRow = this.terminalValue.length - 1;
      this.cursorPosition = this.lastTerminalValue.length;
    } else {
      this.terminalValue[this.currentRow] =
        terminalValue.substring(0, this.cursorPosition) + e + terminalValue.substring(this.cursorPosition);

      if (futureRowLength > this.cols) {
        this.splitTerminalValue();

        this.writeTerminalValue();
        this.currentRow += Math.floor((this.cursorPosition + e.length) / this.cols);
        this.cursorPosition = (this.cursorPosition + e.length) % this.cols;
      } else {
        this.writeTerminalValue();
        this.cursorPosition += e.length;
      }
    }

    this.setPreviousCursorPosition();
  }

  private writeTerminalValue(): void {
    this.terminalValue.forEach((row, index) => {
      this.term.write(row);
      if (this.terminalValue.length > 1 && index !== this.terminalValue.length - 1) {
        this.term.write(NEW_LINE);
        this.typeMultipleTimes(ARROW_LEFT, row.length);
      }
    });
  }
}
