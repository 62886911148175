import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { REGEXES } from '../../constants/regexes';
import { ValidationsEnum } from './validations.enum';
import { ValidatorData } from '../validators/helpers/validator-data.model';

interface Validations {
  [key: string]: (value: any, schema?: any, functionName?: string) => ValidatorData;
}

@Injectable({
  providedIn: 'root',
})
export class FieldsCollectionValidationsService {
  private errorListeners = [];
  private validityObject: ValidatorData;
  private validations: Validations;

  constructor(private translate: TranslateService) {
    this.reset();

    this.validations = {
      [ValidationsEnum.ALIAS_EDITOR]: (value) => {
        this.reset();

        const notAllowedRegexString = REGEXES.RESERVED_KEYWORDS.test(value);
        const allowedRegexString = REGEXES.FIELD_NAME_FORMAT.test(value);
        if (!value) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant('fields-collection.editors.alias-editor.errors.empty');
        }
        if (value && !allowedRegexString) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.alias-editor.errors.illegal_characters',
          );
        }
        if (value && notAllowedRegexString) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.alias-editor.errors.reserved_keywords',
          );
        }

        return this.validityObject;
      },
      [ValidationsEnum.DATABASE_COLUMN_EDITOR]: (value) => {
        this.reset();
        if (!value || String(value).trim() === '') {
          this.validityObject.valid = false;
          this.validityObject.message = 'Destination column name can’t be empty.';
        }
        return this.validityObject;
      },
      [ValidationsEnum.FIELD_PICKER]: (value, schema) => {
        this.reset();

        if (!value) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.field-expression-editor.errors.empty',
          );
        } else if (schema && schema.name) {
          const field = (schema.fields || []).find((item) => item.name === value);
          const valueToShow = value.length > 22 ? `${value.substring(0, 22)}...` : value;
          if (!field) {
            this.validityObject.valid = false;
            this.validityObject.message = this.translate.instant(
              'fields-collection.editors.field-picker.errors.missing_field',
              { fieldName: valueToShow },
            );
          }
        } else if (!schema) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.field-picker.errors.no_input_schema',
          );
        }

        return this.validityObject;
      },
      [ValidationsEnum.ORDERED_FIELDS_PICKER]: (value, schema) => {
        this.reset();

        if (!value || !value.length) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.ordered-fields-picker.errors.empty',
          );
        } else if (schema && schema.name) {
          const missedFields = [];
          value.forEach((name) => {
            const field = schema.fields.find((item) => item.name === name);
            if (!field || (field && field.notExist)) missedFields.push(name);
          });
          if (missedFields.length) {
            this.validityObject.valid = false;
            this.validityObject.message = this.translate.instant(
              'fields-collection.editors.ordered-fields-picker.errors.missing_fields',
            );
          }
        } else if (!schema) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.field-picker.errors.no_input_schema',
          );
        }

        return this.validityObject;
      },
      [ValidationsEnum.WINDOW_ARGUMENTS_EDITOR]: (value, prop, functionName) => {
        this.reset();

        let propsToValidate = ['range_before', 'range_after'];

        enum FUNCTIONS {
          LEAD = 'lead',
          LAG = 'lag',
          NTILE = 'ntile',
        }

        // eslint-disable-next-line default-case
        switch (functionName) {
          case FUNCTIONS.LEAD:
          case FUNCTIONS.LAG:
            propsToValidate = ['default_value', 'offset'];
            break;
          case FUNCTIONS.NTILE:
            propsToValidate = ['range_before', 'range_after', 'ntiles'];
            break;
        }

        const isVariable = (val) => {
          let isVar = false;
          if (typeof val === 'string' && val.length) {
            isVar = value.charAt(0) === '$';
          }
          return isVar;
        };

        if (propsToValidate.indexOf(prop) > -1) {
          if (value) {
            if (prop !== 'default_value' && Number.isNaN(Number(value)) && !isVariable(value)) {
              this.validityObject.valid = false;
              this.validityObject.message = 'Value can be a number or a variable';
              this.validityObject.originatingProp = prop;
            }
          } else if (prop === 'offset' || prop === 'ntiles' || prop === 'default_value') {
            this.validityObject.valid = false;
            this.validityObject.message = "Field can't be empty.";
            this.validityObject.originatingProp = prop;
          }
        }

        return this.validityObject;
      },
      [ValidationsEnum.FIELDS_EXPRESSION_EDITOR]: (value) => {
        this.reset();
        if (value === '') {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.field-expression-editor.errors.empty',
          );
        }
        return this.validityObject;
      },
      [ValidationsEnum.REST_API_HEADER_KEY]: (value) => {
        this.reset();
        if (!value || value.length === 0) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.rest-api-headers-key.errors.empty',
          );
        }
        return this.validityObject;
      },
      [ValidationsEnum.REST_API_HEADER_VALUE]: (value) => {
        this.reset();
        if (!value || value.length === 0) {
          this.validityObject.valid = false;
          this.validityObject.message = this.translate.instant(
            'fields-collection.editors.rest-api-headers-value.errors.empty',
          );
        }
        return this.validityObject;
      },
    };
  }

  private reset() {
    this.validityObject = {
      valid: true,
      message: null,
    };
  }

  getValidationFunction(editorType: ValidationsEnum): (...args: any) => ValidatorData {
    if (editorType) return this.validations[editorType];
    return () => ({ valid: true, message: null });
  }

  validateFields(fields, value, index, prop) {
    const validationFunction = this.getValidationFunction(ValidationsEnum.FIELD_PICKER);
    if (typeof validationFunction === 'function') {
      const validationObj = validationFunction(value, fields);
      if (!validationObj.valid) {
        this.notifyErrorListeners(index, validationObj.message, prop);
      }
      return validationObj;
    }
    return new Error('Fields collection: No validation function found for current editor');
  }

  onError(fn, id) {
    this.errorListeners.push({ fn, id });
  }

  private notifyErrorListeners(index, message, prop) {
    this.errorListeners.forEach((listenerFn) => {
      if (typeof listenerFn === 'function') {
        listenerFn({ index, message, prop });
      }
    });
  }
}
