import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ValidatorFn, Validators } from '@angular/forms';
import { VariableItem } from '../../../package-designer/package.models';
import { CustomMiddleClickEvent } from '../../helper/global-types.helper';
import { ChangeStateService } from '../../services/change-state.service';

@Component({
  selector: 'variables-editor',
  template: `
    <div class="variables-editor variables-editor-wrapper">
      <div class="message-info" *ngIf="items.length > 0 && type === 'global_variables' && !hideGlobalVariablesMessage">
        <strong>Read-only.</strong> Global variables can be defined on the
        <a
          (click)="changeState('settings/account/global-variables', $event)"
          (auxclick)="changeState('settings/account/global-variables', { isMiddleClick: true })"
          >Settings</a
        >
        page.
      </div>

      <div class="message-info" *ngIf="readonlyValue && type === 'secret_variables' && parentType === 'workflow'">
        <strong>Read-only.</strong> It is not possible to override secrets from a workflow.
      </div>
      <div class="message-info" *ngIf="readonlyValue && type === 'secret_variables' && parentType === 'schedule'">
        <strong>Read-only.</strong> It is not possible to override secrets from a schedule.
      </div>
      <div class="message-info" *ngIf="readonlyValue && type === 'secret_variables' && parentType === 'job'">
        <strong>Read-only.</strong> It is not possible to override secrets from a job.
      </div>

      <div [ngSwitch]="type">
        <div class="variables-editor-headers" *ngSwitchCase="'window'">
          {{ 'variables-editor.labels.window' | translate }}
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'select'" [hidden]="items.length === 0">
          <div class="variables-editor-header variables-variable">
            {{ 'variables-editor.labels.expression' | translate }}
          </div>
          <div class="variables-editor-header variables-alias">
            {{ 'variables-editor.labels.alias' | translate }}
          </div>
        </div>

        <div class="variables-editor-headers" *ngSwitchCase="'user_variables'" [hidden]="items.length === 0">
          <div class="variables-editor-header variables-variable">
            {{ 'variables-editor.labels.variable' | translate }}
          </div>
          <div class="variables-editor-header variables-expression">
            {{ 'variables-editor.labels.expression' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'secret_variables'" [hidden]="items.length === 0">
          <div class="variables-editor-header variables-variable">
            {{ 'variables-editor.labels.variable' | translate }}
          </div>
          <div class="variables-editor-header variables-expression">
            {{ 'variables-editor.labels.string_value' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'system_variables'" [hidden]="items.length === 0">
          <div class="variables-editor-header variables-variable">
            {{ 'variables-editor.labels.variable' | translate }}
          </div>
          <div class="variables-editor-header variables-expression">
            {{ 'variables-editor.labels.expression' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'global_variables'" [hidden]="items.length === 0">
          <div class="variables-editor-header variables-variable">
            {{ 'variables-editor.labels.variable' | translate }}
          </div>
          <div class="variables-editor-header variables-expression">
            {{ 'variables-editor.labels.expression' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'cube'">
          <div class="variables-editor-header cube-type">
            {{ 'variables-editor.labels.cube-type' | translate }}
          </div>
          <div class="variables-editor-header cube-fields">
            {{ 'variables-editor.labels.cube-fields' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'sort'" [hidden]="items.length === 0">
          <div class="variables-editor-header sort-by">
            {{ 'variables-editor.labels.sort-by' | translate }}
          </div>
          <div class="variables-editor-header sort-direction">
            {{ 'variables-editor.labels.sort-direction' | translate }}
          </div>
        </div>
        <div class="variables-editor-headers" *ngSwitchCase="'partition'" [hidden]="items.length === 0">
          <div class="variables-editor-header partition-by">
            {{ 'variables-editor.labels.partition-by' | translate }}
          </div>
        </div>
        <div
          class="variables-editor-headers variables-editor-headers-schema"
          *ngSwitchCase="'schema'"
          [hidden]="items.length > 0"
        >
          <div class="variables-editor-header schema-name">
            {{ 'variables-editor.labels.name' | translate }}
          </div>
          <div class="variables-editor-header schema-alias">
            {{ 'variables-editor.labels.alias' | translate }}
          </div>
          <div class="variables-editor-header schema-datatype">
            {{ 'variables-editor.labels.datatype' | translate }}
          </div>
        </div>
      </div>
      <div class="variables-editor-sortable" cdkDropList (cdkDropListDropped)="drop($event)">
        <variables-editor-row
          *ngFor="let item of items; trackBy: identify; let i = index"
          [item]="item"
          [disableKey]="disableKey"
          [readonlyValue]="readonlyValue"
          [secretValue]="secretValue"
          [valueValidators]="valueValidators"
          [readonlyKey]="readonlyKey"
          [hideRemove]="hideRemove"
          [showUndo]="showUndo"
          [usePackageDefault]="usePackageDefault"
          [disableSort]="disableSort"
          [keyPlaceholder]="keyPlaceholder"
          [inputPlaceholder]="inputPlaceholder"
          [key]="key"
          [value]="value"
          [index]="i"
          [type]="type"
          [hideTooltip]="hideTooltip"
          [showPlaceholder]="showPlaceholder"
          (itemChange)="onValueChange($event, i)"
          (itemRemove)="onValueRemove($event, i)"
        ></variables-editor-row>
      </div>
      <div class="variables-editor-empty-text" *ngIf="items.length === 0">
        <h4 *ngIf="type !== 'global_variables'">{{ emptyMessage | translate }}</h4>
        <h4 *ngIf="type === 'global_variables' && !hideGlobalVariablesMessage">
          Global variables can be defined on the
          <a
            (click)="changeState('settings/account/global-variables', $event)"
            (auxclick)="changeState('settings/account/global-variables', { isMiddleClick: true })"
            >Settings</a
          >
          page.
        </h4>
      </div>
      <div class="variables-editor-row variables-editor-add" *ngIf="!hideAdd">
        <button type="button" class="btn btn-block btn-lg" (click)="add()" tabindex="-1">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="7px" height="7px" viewBox="0 0 7 7">
            <path style="fill: #3f3f3f;" d="M4,7H3V4H0V3h3V0h1v3h3v1H4V7z" />
          </svg>
          <span *ngIf="items.length === 0">{{ 'variables-editor.labels.add_new' | translate }}</span>
          <span *ngIf="items.length > 0">{{ 'variables-editor.labels.add_another' | translate }}</span>
        </button>
      </div>
    </div>
  `,
})
export class VariablesEditorComponent {
  @Input() items: VariableItem[];
  @Input() key = 'key';
  @Input() value = 'value';
  @Input() type = '';
  @Input() parentType: string = '';
  @Input() hideRemove = false;
  @Input() hideAdd = false;
  @Input() showUndo = false;
  @Input() disableKey = false;
  @Input() disableSort = false;
  @Input() readonlyValue = false;
  @Input() secretValue = false;
  @Input() readonlyKey = false;
  @Input() keyPlaceholder: string = '';
  @Input() inputPlaceholder: string = '';
  @Input() usePackageDefault = false;
  @Input() hideTooltip = false;
  @Input() showPlaceholder = '';
  @Input() emptyMessage = 'variables-editor.labels.empty-list';
  @Input() hideGlobalVariablesMessage = false;
  @Input() valueValidators: ValidatorFn[] = [];
  @Output() variablesChange = new EventEmitter();

  constructor(private changeStateService: ChangeStateService) {}

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
  }

  onValueChange(item, index: number) {
    this.items[index] = item;

    this.variablesChange.emit(this.items);
  }

  onValueRemove(item, index: number) {
    this.items = this.items.filter((_, i) => index !== i);

    this.variablesChange.emit(this.items);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.variablesChange.emit(this.items);
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index, item) {
    return this.hideRemove ? item.key : index;
  }

  add() {
    const newItem: any = {};

    newItem[this.key] = '';
    newItem[this.value] = '';
    newItem.data_type = 'string';

    this.items = this.items.concat(newItem);

    this.variablesChange.emit(this.items);
  }

  protected readonly Validators = Validators;
}
