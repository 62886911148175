import { SelectOption } from '../common/components/forms/xp-select.component';

export const HUBSPOT_ASSOCIATIONS_TYPES: {
  [key: string]: SelectOption[];
} = {
  companies: [
    { text: 'Company to company (id: 450)', value: 450 },
    { text: 'Child to parent company (id: 14)', value: 14 },
    { text: 'Parent to child company (id: 13)', value: 13 },
    { text: 'Company to contact (id: 280)', value: 280 },
    { text: 'Company to contact (Primary (id: 2))', value: 2 },
    { text: 'Company to deal (id: 342)', value: 342 },
    { text: 'Company to deal (Primary (id: 6))', value: 6 },
    { text: 'Company to ticket (id: 340)', value: 340 },
    { text: 'Company to ticket (Primary (id: 25))', value: 25 },
    { text: 'Company to call (id: 181)', value: 181 },
    { text: 'Company to email (id: 185)', value: 185 },
    { text: 'Company to meeting (id: 187)', value: 187 },
    { text: 'Company to note (id: 189)', value: 189 },
    { text: 'Company to task (id: 191)', value: 191 },
    { text: 'Company to communication (id: 88)', value: 88 },
    { text: 'Company to postal mail (id: 460)', value: 460 },
    { text: 'Company to invoice (id: 180)', value: 180 },
    { text: 'Company to order (id: 510)', value: 510 },
    { text: 'Company to payment (id: 390)', value: 390 },
    { text: 'Company to subscription (id: 298)', value: 298 },
    { text: 'Company to appointment (id: 909)', value: 909 },
    { text: 'Company to course (id: 939)', value: 939 },
    { text: 'Company to listing (id: 885)', value: 885 },
    { text: 'Company to service (id: 793)', value: 793 },
  ],
  contacts: [
    { text: 'Contact to contact (id: 449)', value: 449 },
    { text: 'Contact to company (id: 279)', value: 279 },
    { text: 'Contact to company (Primary (id: 1))', value: 1 },
    { text: 'Contact to deal (id: 4)', value: 4 },
    { text: 'Contact to ticket (id: 15)', value: 15 },
    { text: 'Contact to call (id: 193)', value: 193 },
    { text: 'Contact to email (id: 197)', value: 197 },
    { text: 'Contact to meeting (id: 199)', value: 199 },
    { text: 'Contact to note (id: 201)', value: 201 },
    { text: 'Contact to task (id: 203)', value: 203 },
    { text: 'Contact to communication (id: 82)', value: 82 },
    { text: 'Contact to postal mail (id: 454)', value: 454 },
    { text: 'Contact to cart (id: 587)', value: 587 },
    { text: 'Contact to order (id: 508)', value: 508 },
    { text: 'Contact to invoice (id: 178)', value: 178 },
    { text: 'Contact to payment (id: 388)', value: 388 },
    { text: 'Contact to subscription (id: 296)', value: 296 },
    { text: 'Contact to appointment (id: 907)', value: 907 },
    { text: 'Contact to course (id: 861)', value: 861 },
    { text: 'Contact to listing (id: 883)', value: 883 },
    { text: 'Contact to service (id: 799)', value: 799 },
  ],
  deals: [
    { text: 'Deal to deal (id: 451)', value: 451 },
    { text: 'Deal to contact (id: 3)', value: 3 },
    { text: 'Deal to company (id: 341)', value: 341 },
    { text: 'Deal to company (Primary (id: 5))', value: 5 },
    { text: 'Deal to ticket (id: 27)', value: 27 },
    { text: 'Deal to call (id: 205)', value: 205 },
    { text: 'Deal to email (id: 209)', value: 209 },
    { text: 'Deal to meeting (id: 211)', value: 211 },
    { text: 'Deal to note (id: 213)', value: 213 },
    { text: 'Deal to task (id: 215)', value: 215 },
    { text: 'Deal to communication (id: 86)', value: 86 },
    { text: 'Deal to postal mail (id: 458)', value: 458 },
    { text: 'Deal to deal split (id: 313)', value: 313 },
    { text: 'Deal to line item (id: 19)', value: 19 },
    { text: 'Deal to invoice (id: 176)', value: 176 },
    { text: 'Deal to order (id: 511)', value: 511 },
    { text: 'Deal to payment (id: 392)', value: 392 },
    { text: 'Deal to quote (id: 63)', value: 63 },
    { text: 'Deal to subscription (id: 300)', value: 300 },
    { text: 'Deal to appointment (id: 945)', value: 945 },
    { text: 'Deal to course (id: 863)', value: 863 },
    { text: 'Deal to listing (id: 887)', value: 887 },
    { text: 'Deal to service (id: 795)', value: 795 },
  ],
  tickets: [
    { text: 'Ticket to ticket (id: 452)', value: 452 },
    { text: 'Ticket to contact (id: 16)', value: 16 },
    { text: 'Ticket to company (id: 339)', value: 339 },
    { text: 'Ticket to company (Primary (id: 26))', value: 26 },
    { text: 'Ticket to deal (id: 28)', value: 28 },
    { text: 'Ticket to call (id: 219)', value: 219 },
    { text: 'Ticket to email (id: 223)', value: 223 },
    { text: 'Ticket to meeting (id: 225)', value: 225 },
    { text: 'Ticket to note (id: 227)', value: 227 },
    { text: 'Ticket to task (id: 229)', value: 229 },
    { text: 'Ticket to communication (id: 84)', value: 84 },
    { text: 'Ticket to postal mail (id: 456)', value: 456 },
    { text: 'Ticket to thread (id: 32)', value: 32 },
    { text: 'Ticket to conversation (id: 278)', value: 278 },
    { text: 'Ticket to order (id: 526)', value: 526 },
    { text: 'Ticket to appointment (id: 947)', value: 947 },
    { text: 'Ticket to course (id: 941)', value: 941 },
    { text: 'Ticket to listing (id: 943)', value: 943 },
    { text: 'Ticket to service (id: 797)', value: 797 },
  ],
  appointments: [
    { text: 'Appointment to contact (id: 966)', value: 966 },
    { text: 'Appointment to company (id: 908)', value: 908 },
    { text: 'Appointment to deal (id: 944)', value: 944 },
    { text: 'Appointment to ticket (id: 946)', value: 946 },
    { text: 'Appointment to call (id: 912)', value: 912 },
    { text: 'Appointment to email (id: 916)', value: 916 },
    { text: 'Appointment to meeting (id: 918)', value: 918 },
    { text: 'Appointment to note (id: 920)', value: 920 },
    { text: 'Appointment to task (id: 922)', value: 922 },
    { text: 'Appointment to communication (id: 924)', value: 924 },
    { text: 'Appointment to postal mail (id: 926)', value: 926 },
  ],
  courses: [
    { text: 'Course to contact (id: 860)', value: 860 },
    { text: 'Course to company (id: 938)', value: 938 },
    { text: 'Course to deal (id: 862)', value: 862 },
    { text: 'Course to ticket (id: 940)', value: 940 },
    { text: 'Course to call (id: 866)', value: 866 },
    { text: 'Course to email (id: 870)', value: 870 },
    { text: 'Course to meeting (id: 872)', value: 872 },
    { text: 'Course to note (id: 874)', value: 874 },
    { text: 'Course to task (id: 876)', value: 876 },
    { text: 'Course to communication (id: 878)', value: 878 },
    { text: 'Course to postal mail (id: 880)', value: 880 },
  ],
  listings: [
    { text: 'Listing to contact (id: 882)', value: 882 },
    { text: 'Listing to company (id: 884)', value: 884 },
    { text: 'Listing to deal (id: 886)', value: 886 },
    { text: 'Listing to ticket (id: 942)', value: 942 },
    { text: 'Listing to call (id: 890)', value: 890 },
    { text: 'Listing to email (id: 894)', value: 894 },
    { text: 'Listing to meeting (id: 896)', value: 896 },
    { text: 'Listing to note (id: 898)', value: 898 },
    { text: 'Listing to task (id: 900)', value: 900 },
    { text: 'Listing to communication (id: 902)', value: 902 },
    { text: 'Listing to postal mail (id: 904)', value: 904 },
  ],
  services: [
    { text: 'Service to contact (id: 798)', value: 798 },
    { text: 'Service to company (id: 792)', value: 792 },
    { text: 'Service to deal (id: 794)', value: 794 },
    { text: 'Service to ticket (id: 796)', value: 796 },
    { text: 'Service to call (id: 840)', value: 840 },
    { text: 'Service to email (id: 842)', value: 842 },
    { text: 'Service to meeting (id: 838)', value: 838 },
    { text: 'Service to note (id: 836)', value: 836 },
    { text: 'Service to task (id: 852)', value: 852 },
    { text: 'Service to communication (id: 846)', value: 846 },
    { text: 'Service to postal mail (id: 848)', value: 848 },
  ],
  calls: [
    { text: 'Call to contact (id: 194)', value: 194 },
    { text: 'Call to company (id: 182)', value: 182 },
    { text: 'Call to deal (id: 206)', value: 206 },
    { text: 'Call to ticket (id: 220)', value: 220 },
    { text: 'Call to appointment (id: 913)', value: 913 },
    { text: 'Call to course (id: 867)', value: 867 },
    { text: 'Call to listing (id: 891)', value: 891 },
    { text: 'Call to service (id: 841)', value: 841 },
  ],
  emails: [
    { text: 'Email to contact (id: 198)', value: 198 },
    { text: 'Email to company (id: 186)', value: 186 },
    { text: 'Email to deal (id: 210)', value: 210 },
    { text: 'Email to ticket (id: 224)', value: 224 },
    { text: 'Email to appointment (id: 917)', value: 917 },
    { text: 'Email to course (id: 871)', value: 871 },
    { text: 'Email to listing (id: 895)', value: 895 },
    { text: 'Email to service (id: 843)', value: 843 },
  ],
  meetings: [
    { text: 'Meeting to contact (id: 200)', value: 200 },
    { text: 'Meeting to company (id: 188)', value: 188 },
    { text: 'Meeting to deal (id: 212)', value: 212 },
    { text: 'Meeting to ticket (id: 226)', value: 226 },
    { text: 'Meeting to appointment (id: 919)', value: 919 },
    { text: 'Meeting to course (id: 873)', value: 873 },
    { text: 'Meeting to listing (id: 897)', value: 897 },
    { text: 'Meeting to service (id: 839)', value: 839 },
  ],
  notes: [
    { text: 'Note to contact (id: 202)', value: 202 },
    { text: 'Note to company (id: 190)', value: 190 },
    { text: 'Note to deal (id: 214)', value: 214 },
    { text: 'Note to ticket (id: 228)', value: 228 },
    { text: 'Note to appointment (id: 921)', value: 921 },
    { text: 'Note to course (id: 875)', value: 875 },
    { text: 'Note to listing (id: 899)', value: 899 },
    { text: 'Note to service (id: 837)', value: 837 },
  ],
  postalmails: [
    { text: 'Postal mail to contact (id: 453)', value: 453 },
    { text: 'Postal mail to company (id: 459)', value: 459 },
    { text: 'Postal mail to deal (id: 457)', value: 457 },
    { text: 'Postal mail to ticket (id: 455)', value: 455 },
    { text: 'Postal mail to appointment (id: 927)', value: 927 },
    { text: 'Postal mail to course (id: 881)', value: 881 },
    { text: 'Postal mail to listing (id: 905)', value: 905 },
    { text: 'Postal mail to service (id: 849)', value: 849 },
  ],
  quotes: [
    { text: 'Quote to contact (id: 69)', value: 69 },
    { text: 'Quote to company (id: 71)', value: 71 },
    { text: 'Quote to deal (id: 64)', value: 64 },
    { text: 'Quote to line item (id: 67)', value: 67 },
    { text: 'Quote to quote template (id: 286)', value: 286 },
    { text: 'Quote to discount (id: 362)', value: 362 },
    { text: 'Quote to fee (id: 364)', value: 364 },
    { text: 'Quote to tax (id: 366)', value: 366 },
    { text: 'Contact signer (id: 702)', value: 702 },
    { text: 'Quote to cart (id: 733)', value: 733 },
    { text: 'Quote to invoice (id: 408)', value: 408 },
    { text: 'Quote to order (id: 731)', value: 731 },
    { text: 'Quote to payment (id: 398)', value: 398 },
    { text: 'Quote to subscription (id: 304)', value: 304 },
  ],
  tasks: [
    { text: 'Task to contact (id: 204)', value: 204 },
    { text: 'Task to company (id: 192)', value: 192 },
    { text: 'Task to deal (id: 216)', value: 216 },
    { text: 'Task to ticket (id: 230)', value: 230 },
    { text: 'Task to appointment (id: 923)', value: 923 },
    { text: 'Task to course (id: 877)', value: 877 },
    { text: 'Task to listing (id: 901)', value: 901 },
    { text: 'Task to service (id: 853)', value: 853 },
  ],
  communications: [
    { text: 'Communication to contact (id: 81)', value: 81 },
    { text: 'Communication to company (id: 87)', value: 87 },
    { text: 'Communication to deal (id: 85)', value: 85 },
    { text: 'Communication to ticket (id: 83)', value: 83 },
    { text: 'Communication to appointment (id: 925)', value: 925 },
    { text: 'Communication to course (id: 879)', value: 879 },
    { text: 'Communication to listing (id: 903)', value: 903 },
    { text: 'Communication to service (id: 847)', value: 847 },
  ],
  orders: [
    { text: 'Order to cart (id: 593)', value: 593 },
    { text: 'Order to contact (id: 507)', value: 507 },
    { text: 'Order to company (id: 509)', value: 509 },
    { text: 'Order to deal (id: 512)', value: 512 },
    { text: 'Order to discount (id: 519)', value: 519 },
    { text: 'Order to discount code (id: 521)', value: 521 },
    { text: 'Order to invoice (id: 518)', value: 518 },
    { text: 'Order to line item (id: 513)', value: 513 },
    { text: 'Order to payment (id: 523)', value: 523 },
    { text: 'Order to quote (id: 730)', value: 730 },
    { text: 'Order to subscription (id: 516)', value: 516 },
    { text: 'Order to task (id: 726)', value: 726 },
    { text: 'Order to ticket (id: 525)', value: 525 },
  ],
  carts: [
    { text: 'Cart to contact (id: 586)', value: 586 },
    { text: 'Cart to discount (id: 588)', value: 588 },
    { text: 'Cart to line item (id: 590)', value: 590 },
    { text: 'Cart to order (id: 592)', value: 592 },
    { text: 'Cart to quote (id: 732)', value: 732 },
    { text: 'Cart to task (id: 728)', value: 728 },
    { text: 'Cart to ticket (id: 594)', value: 594 },
  ],
};
