
export const environment = {
      production: true,
      ACCOUNT_DOCK_PUBLIC_KEY: 'ad_acco_u61tcr598hqljyxb',
      API_URL: 'https://api.xplenty.com',
      APP_URL: 'https://app.xplenty.com',
      CONSOLE_URL: 'https://labs.xplenty.com/services/pig/evaluate',
      NODE_ENV: 'production',
      PUSHER_PUBLIC_KEY: '584a3bf1df1024ac37f0',
      SEGMENT_PUBLIC_KEY: 'hWK61fl8lXoRrqrK5wlL9cX18jFguC8P',
      STRIPE_PUBLIC_KEY: 'pk_live_A2Bkxw89sEFrvW3IsB0w5AMh',
      PIPEDRIVE_URL: 'https://gateway.xplenty.com/send-hubspot-leads',
      PIPEDRIVE_RETURN_URL: 'https://www.xplenty.com/calendly/',
      SENTRY_ENV: 'production',
      SOURCE_VERSION: '48f954e212cd7e29986d3d8fed310d8752fb0637',
      INTEGRATEIO_URL: 'https://dashboard.integrate.io',
      TESTS_FILTER: 'undefined',
      SENTRY_DSN: 'https://75f7d6ae3fb2e8095ed958bc47413e82@o4507576419680256.ingest.us.sentry.io/4507803100577792',
      SENTRY_ENABLED: true,
};
