import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppState } from './store';
import { getConnectionTypes } from './connections/store/connections.actions';
import { ScrollService } from './common/services/scroll.service';
import { getJobsList, getJobsListResponse } from './jobs/store/jobs.actions';
import { defaultJobsListQueryParams } from './jobs/components/jobs-list.component';

@Component({
  selector: 'account-component',
  template: `
    <div class="main-wrapper" [ngClass]="{ 'is-boomerang': isBoomerang }">
      <button
        aria-label="mobile navigation button"
        type="button"
        class="mobile-icon"
        [ngClass]="{ open: isOpen }"
        (click)="toggleMobileNavigation()"
        id="mobile-icon"
      >
        <span></span>
      </button>

      <div class="sidebar-wrapper">
        <sidebar [isOpen]="isOpen" (toggleMenu)="toggleMenuFromSidebar()"></sidebar>
      </div>
      <div class="page-content-wrapper" [ngClass]="{ 'is-settings-route': isSubWrapperVisible }">
        <integrateio-notification></integrateio-notification>
        <div class="page-top-header">
          <xp-onboarding-toolbar></xp-onboarding-toolbar>
        </div>
        <div class="page-scroll-wrapper" (scroll)="onScroll($event)">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <help-menu [isOpen]="isOpen"></help-menu>
    </div>
  `,
})
export class AccountComponent implements OnInit, OnDestroy {
  isSubWrapperVisible = false;
  accountId = this.route.snapshot.params.account_id;
  routerEndSubscription: Subscription;
  isBoomerang = false;
  isOpen = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private scrollService: ScrollService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isBoomerang = !!document.getElementById('heroku-boomerang');
    this.isSubWrapperVisible = this.router.url.includes('/settings');

    this.routerEndSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: (event: NavigationEnd) => {
        this.isSubWrapperVisible = this.router.url.includes('/settings');
        const newAccountID = event.url.match(/[\w-]+/g)[0];

        if (newAccountID !== this.accountId) {
          this.accountId = newAccountID;

          if (this.router.url.includes('/jobs')) {
            this.store.dispatch(getJobsListResponse({ jobs: [] }));
            this.store.dispatch(getJobsList({ params: defaultJobsListQueryParams }));
          }
        }
      },
    });

    this.store.dispatch(getConnectionTypes());
  }

  onScroll(event) {
    const eventTarget = event.target as HTMLElement;
    if (
      eventTarget.scrollTop === 0 ||
      eventTarget.offsetHeight + eventTarget.scrollTop < eventTarget.scrollHeight - 50
    ) {
      return;
    }

    this.scrollService.scrollYReachEnd(event);
  }

  toggleMobileNavigation() {
    this.isOpen = !this.isOpen;
  }

  toggleMenuFromSidebar() {
    this.isOpen = false;
  }

  ngOnDestroy() {
    if (this.routerEndSubscription) {
      this.routerEndSubscription.unsubscribe();
    }
  }
}
