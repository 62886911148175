import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AnyConnection } from '../../connection.models';

export const DUMMY_PASSWORD = '_DUMMY_PASSWORD_';

@Component({
  selector: 'connection-component-username-password',
  template: `
    <div class="connection-component-username-password">
      <xp-form-group>
        <label for="username"
          >{{ usernameTranslationKey | translate }}
          <i
            *ngIf="userNameTooltip"
            class="fa fa-exclamation-circle"
            [matTooltip]="'connections.form.hints.azure_user_name' | translate"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </label>
        <xp-input
          type="text"
          class="form-control"
          name="username"
          id="username"
          [(ngModel)]="connection.username"
        ></xp-input>
      </xp-form-group>
      <xp-form-group *ngIf="connection.auth_method !== 'key'" [validationDisabled]="isValidationDisabled()">
        <label for="password">
          {{ passwordTranslationKey | translate }}
          <span *ngIf="passwordChangeable">
            (<a (click)="change()"
              ><span *ngIf="passwordDisabled">{{ 'connections.form.labels.change' | translate }}</span
              ><span *ngIf="!passwordDisabled">{{ 'connections.form.labels.cancel' | translate }}</span></a
            >)
          </span>
        </label>
        <xp-input
          type="password"
          class="form-control"
          name="password"
          id="password"
          [(ngModel)]="connection.password"
          [attr.disabled]="passwordDisabled"
          [disable]="passwordDisabled"
          (blur)="inFocus = false"
          (focus)="inFocus = true"
          autocomplete="new-password"
        ></xp-input>
      </xp-form-group>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentUsernamePasswordComponent implements OnInit {
  @Input() connection: Partial<AnyConnection> = {};
  @Input() usernameTranslationKey = 'connections.form.labels.username';
  @Input() passwordTranslationKey = 'connections.form.labels.password';
  @Input() userNameTooltip = false;

  inFocus = false;
  passwordChangeable = false;
  passwordDisabled = true;

  ngOnInit() {
    this.passwordChangeable = !!this.connection.id;
    this.passwordDisabled = this.passwordChangeable;

    if (this.passwordDisabled) {
      this.connection.password = DUMMY_PASSWORD;
    }
  }

  isValidationDisabled(): boolean {
    return this.passwordDisabled;
  }

  change(): void {
    this.passwordDisabled = !this.passwordDisabled;

    if (this.passwordDisabled) {
      this.connection.password = DUMMY_PASSWORD;
    } else {
      this.connection.password = '';
    }
  }
}
