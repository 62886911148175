import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { debounceTime, filter } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { AppState } from '../../../../store';
import {
  loadSelectPickerItem,
  loadSelectPickerItemProps,
  loadSelectPickerItems,
  loadSelectPickerItemsWithSearch,
} from '../../../store/select-picker.actions';
import {
  selectIsLazyLoadingFlagForSelectPicker,
  selectIsLoadingFlagForSelectPicker,
  selectItemsForSelectPicker,
} from '../../../store/select-picker.selectors';
import { queryParamsMap, SelectPickerTypes } from './select-picker-types.enum';
import { AnyConnection } from '../../../../connections/connection.models';
import { selectLastlyCreatedCluster } from '../../../../clusters/store/clusters.selectors';
import { Cluster } from '../../../../clusters/clusters.models';
import { selectLastlyCreatedConnection } from '../../../../connections/store/connections.selectors';
import { uid } from '../../../../package-designer/helpers/components.helpers';
import { ListParams } from '../../../helper/query-params-generic-list.helper';
import { Package } from '../../../../packages/package.models';
import { Workspace } from '../../../../workspaces/workspaces.models';
import { Connection } from '../../../../package-designer/package.models';

export type SelectPickerValue = Package | AnyConnection | Cluster | Workspace | Partial<Connection>;

@Component({
  selector: 'xp-select-picker',
  template: `
    <mat-select
      [value]="getItemId(value)"
      (valueChange)="onValueChange($event)"
      [id]="id"
      [disabled]="isDisabled"
      [placeholder]="getPlaceholder()"
      class="select-picker-mat"
      panelClass="select-picker"
      disableOptionCentering
      msInfiniteScroll
      (infiniteScroll)="loadMoreItems()"
      [complete]="offset > items.length"
      #matSelect
    >
      <mat-option>
        <ngx-mat-select-search
          [clearSearchInput]="false"
          [ngModel]="searchValue"
          (ngModelChange)="FilterOptionsSubject.next($event)"
          [placeholderLabel]="getPlaceholder() || ''"
          [noEntriesFoundLabel]="isLoading ? 'Loading data...' : 'No results found'"
          [disableScrollToActiveOnOptionsChanged]="true"
        >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of items; trackBy: identify" [value]="getItemId(item)">
        <div [ngSwitch]="type">
          <xp-select-picker-package
            [package]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes.package"
          ></xp-select-picker-package>
          <xp-select-picker-connection
            (editConnection)="editConnectionHandler($event)"
            [connection]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes.connection"
          ></xp-select-picker-connection>
          <xp-select-picker-cluster
            [cluster]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes.cluster"
          ></xp-select-picker-cluster>
          <xp-select-picker-package-version
            [packageVersion]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes['package-version']"
          ></xp-select-picker-package-version>
          <xp-select-picker-schedule-package-version
            [schedulePackageVersion]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes['schedule-package-version']"
          ></xp-select-picker-schedule-package-version>
          <xp-select-picker-workspace
            [workspace]="$any(item)"
            [highlightValue]="searchValue"
            *ngSwitchCase="selectPickerTypes.workspace"
          ></xp-select-picker-workspace>
        </div>
      </mat-option>
      <mat-option *ngIf="isLazyLoading">
        <xp-loader></xp-loader>
      </mat-option>
      <mat-select-trigger>
        <div class="select-picker-item-container" [ngSwitch]="type">
          <xp-select-picker-package
            [package]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes.package"
          ></xp-select-picker-package>
          <xp-select-picker-connection
            (editConnection)="editConnection.emit($event)"
            [connection]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes.connection"
          ></xp-select-picker-connection>
          <xp-select-picker-cluster
            [cluster]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes.cluster"
          ></xp-select-picker-cluster>
          <xp-select-picker-package-version
            [packageVersion]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes['package-version']"
          ></xp-select-picker-package-version>
          <xp-select-picker-schedule-package-version
            [schedulePackageVersion]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes['schedule-package-version']"
          ></xp-select-picker-schedule-package-version>
          <xp-select-picker-workspace
            [workspace]="$any(selectedItem || {})"
            *ngSwitchCase="selectPickerTypes.workspace"
          ></xp-select-picker-workspace>
        </div>
      </mat-select-trigger>
    </mat-select>
    <input #inputRef class="hidden" [id]="inputId + '1'" [name]="id" type="text" />
  `,
})
export class XpSelectPickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() value: SelectPickerValue;
  @Output() valueChange = new EventEmitter<SelectPickerValue>();
  @Input() type: SelectPickerTypes;
  @Input() filterPackagesWithWorkspace: boolean;
  @Input() filteredItems: Array<number | string>;
  @Input() addItemToList: SelectPickerValue;
  @Input() params: ListParams = {};
  @Input() placeholder: string = '';
  @Input() emptyPlaceholder: string = '';
  @Input() isDisabled: boolean;
  @Input() preventEmpty: boolean;
  @Input() definedId: string;
  @Input() emitInitial: boolean;
  @Output() editConnection = new EventEmitter<AnyConnection>();
  @ViewChild('matSelect') matSelect: MatSelect;
  isLoading: boolean = true;
  isLazyLoading = false;
  searchValue = '';
  valueId: number;
  isInitialChange = true;
  hasItemBeenLoaded = false;
  offset = 100;
  inputId = uid();
  FilterOptionsSubject = new Subject<string>();

  items: SelectPickerValue[] = [];

  itemsSubscription: Subscription;
  isLoadingSubscription: Subscription;
  isLazyLoadingSubscription: Subscription;
  clusterSubscription: Subscription;
  connectionSubscription: Subscription;

  selectPickerTypes = SelectPickerTypes;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    const params = { ...queryParamsMap[this.type], ...this.params };
    this.offset = params.limit;
    this.store.dispatch(loadSelectPickerItems({ id: this.id, itemType: this.type, params }));

    this.itemsSubscription = this.store.select(selectItemsForSelectPicker(this.id, this.type)).subscribe((items) => {
      this.items = items;
      if (typeof this.value === 'number') {
        if (!items.length) {
          this.valueId = this.value;
        } else {
          const item = this.findItem(this.valueId);

          if (!item && !this.hasItemBeenLoaded && this.valueId) {
            this.store.dispatch(
              loadSelectPickerItem({ id: this.id, itemType: this.type, itemId: this.valueId, params }),
            );

            this.hasItemBeenLoaded = true;
            return;
          }

          this.value = item;

          if (item) {
            if (this.isInitialChange) {
              this.onValueChange(this.getItemId(item));
              this.isInitialChange = false;
            }
          }
        }
      } else if (items.length) {
        const itemId = this.getItemId(this.value);
        const item = this.findItem(itemId);
        if (!item && !this.hasItemBeenLoaded && itemId && itemId !== -1) {
          const props: loadSelectPickerItemProps = {
            itemType: this.type,
            itemId: itemId as number,
            params,
            id: this.id,
          };

          if (this.type === SelectPickerTypes.connection) {
            props.connectionType = (this.value as Connection).type;
          }

          this.store.dispatch(loadSelectPickerItem(props));

          this.hasItemBeenLoaded = true;
          return;
        }

        this.value = item;

        if (item) {
          if (this.isInitialChange) {
            this.onValueChange(this.getItemId(item));
            this.isInitialChange = false;
          }
        }
      }

      if (this.type === SelectPickerTypes.workspace) {
        this.items = [{ id: -1, name: 'No workspace', packages: [] } as Workspace, ...this.items];
        if (!this.value) {
          this.value = this.findItem(-1);
        }
      }

      if (this.type === SelectPickerTypes['package-version']) {
        this.items = this.items.slice(1, this.items.length);
      }

      this.filterItems(this.filteredItems, this.filterPackagesWithWorkspace);

      if (this.preventEmpty && !this.value && this.items.length) {
        if (this.isInitialChange) {
          this.onValueChange(this.getItemId(this.items[0]));
          this.isInitialChange = false;
        }
      }
    });

    this.FilterOptionsSubject.pipe(debounceTime(250)).subscribe((value) => {
      this.filterOptions(value);
    });

    this.isLoadingSubscription = this.store
      .select(selectIsLoadingFlagForSelectPicker(this.id, this.type))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.isLazyLoadingSubscription = this.store
      .select(selectIsLazyLoadingFlagForSelectPicker(this.id, this.type))
      .subscribe((isLazyLoading) => {
        this.isLazyLoading = isLazyLoading;
      });

    if (this.type === SelectPickerTypes.cluster) {
      this.clusterSubscription = this.store
        .select(selectLastlyCreatedCluster)
        .pipe(filter(Boolean))
        .subscribe((cluster: Cluster) => {
          this.items = [cluster, ...this.items.filter((item) => item.id !== cluster.id)];
        });
    }

    if (this.type === SelectPickerTypes.connection) {
      this.connectionSubscription = this.store
        .select(selectLastlyCreatedConnection)
        .pipe(filter(Boolean))
        .subscribe((connection: AnyConnection) => {
          this.items = [connection, ...this.items.filter((item) => item.id !== connection.id)];
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addItemToList && changes.addItemToList.currentValue) {
      this.items = [...this.items, changes.addItemToList.currentValue];
      this.value = null;
      this.matSelect.value = null;
    }

    if (changes.filteredItems && changes.filteredItems.currentValue) {
      this.filterItems(changes.filteredItems.currentValue);
    }

    if (typeof this.value === 'number' && this.items.length) {
      this.value = this.findItem(this.value);
    }

    if (
      changes.params &&
      changes.params.currentValue &&
      !isEqual(changes.params.currentValue, changes.params.previousValue) &&
      !changes.value
    ) {
      const params = { ...queryParamsMap[this.type], ...changes.params.currentValue };
      this.store.dispatch(loadSelectPickerItems({ id: this.id, itemType: this.type, params }));
    }
  }

  get selectedItem(): SelectPickerValue {
    return (
      (this.items || []).find(
        (item) => this.getItemId(item) === this.valueId || this.getItemId(item) === this.getItemId(this.value || {}),
      ) || this.value
    );
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item: SelectPickerValue) {
    return item.id;
  }

  loadMoreItems() {
    const params = { ...queryParamsMap[this.type], ...this.params, offset: this.offset };
    this.store.dispatch(loadSelectPickerItems({ id: this.id, itemType: this.type, params, isLazyLoad: true }));
    this.offset += params.limit;
  }

  editConnectionHandler(connection: AnyConnection) {
    this.matSelect.close();
    this.editConnection.emit(connection);
  }

  getPlaceholder(): string {
    if (this.isLoading) {
      return 'Loading...';
    }
    return this.items.length ? this.placeholder : this.emptyPlaceholder;
  }

  getItemId(item: SelectPickerValue = {} as SelectPickerValue): number | string {
    if (this.definedId) {
      return (item || {})[this.definedId];
    }

    const itemId = (item || {}).id;

    if (this.items && this.items[0]) {
      if (typeof this.items[0].id === 'number' && typeof itemId === 'string') {
        return Number(itemId);
      }

      if (typeof this.items[0].id === 'string' && typeof itemId === 'number') {
        return String(itemId);
      }

      return itemId;
    }

    return itemId;
  }

  filterItems(filteredItems: Array<string | number>, filterPackagesWithWorkspace = false) {
    this.items = this.items
      .filter((item) => (filteredItems ? !filteredItems.includes(this.getItemId(item)) : true))
      .filter((item) => (filterPackagesWithWorkspace ? !(item as Package).workspace_id : true));
  }

  filterOptions(searchValue: string) {
    this.searchValue = searchValue;
    const params = { ...queryParamsMap[this.type], q: searchValue, ...this.params };

    if (!searchValue) {
      this.store.dispatch(loadSelectPickerItems({ id: this.id, itemType: this.type, params }));
    } else {
      this.store.dispatch(loadSelectPickerItemsWithSearch({ id: this.id, itemType: this.type, params }));
    }
  }

  findItem(itemId: number | string) {
    return this.items.find((item) => this.getItemId(item) === itemId);
  }

  onValueChange(itemId: number | string) {
    const item = this.findItem(itemId);
    this.valueId = Number(itemId);
    this.valueChange.emit(item);
  }

  ngOnDestroy() {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }

    if (this.isLoadingSubscription) {
      this.isLoadingSubscription.unsubscribe();
    }

    if (this.isLazyLoadingSubscription) {
      this.isLazyLoadingSubscription.unsubscribe();
    }

    if (this.clusterSubscription) {
      this.clusterSubscription.unsubscribe();
    }

    if (this.connectionSubscription) {
      this.connectionSubscription.unsubscribe();
    }
  }
}
