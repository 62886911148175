/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'live-support-btn',
  template: `
    <a
      (click)="show()"
      class="intercom"
      data-category="Help"
      [attr.data-label]="'help-menu.labels.live_support' | translate"
    >
      <svg class="icon icon-chat">
        <use href="#icon-chat"></use></svg
      ><span>{{ 'help-menu.labels.live_support' | translate }}</span></a
    >
  `,
})
export class LiveSupportBtnComponent {
  @Input() message: string;

  show() {
    if (window['Intercom']) {
      window['Intercom']('show');
      window['Intercom']('showNewMessage', this.message);
    }
  }
}
