import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Credentials } from '../../common/services/auth.service';
import { UsersResource } from '../resources/users.resource';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sign-up-free-trial',
  template: `
    <div class="panel">
      <div class="panel-header">
        <div class="panel-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="70"
            height="50"
            viewBox="0 0 420 296"
          >
            <defs>
              <style>
                .cls-1 {
                  fill: #2d3e4a;
                }

                .cls-1,
                .cls-2 {
                  fill-rule: evenodd;
                }

                .cls-2 {
                  fill: url(#linear-gradient);
                }
              </style>
              <linearGradient
                id="linear-gradient"
                x1="197.094"
                y1="257"
                x2="197.094"
                y2="29"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#43dcff" />
                <stop offset="1" stop-color="#25a5ff" />
              </linearGradient>
            </defs>
            <path
              id="Gradient_Fill_1"
              data-name="Gradient Fill 1"
              class="cls-2"
              d="M131,29l57,31c-0.333.667-1.667,1.333-3.65,2.841-3.4,2.022-6.693,4.5-11.35,7.159-11.675-6.182-31.822-17.881-40-22C105.669,64,76.523,78.814,50,96c0,31.663,1,63.337,1,95,27.664,15.332,50.444,29.646,81,48,15-8.666,32-19.334,47-28q-0.5-1-1-2l-16-9V85l9-5,17,11h-2c-2.333,1.333-5.667,3.667-8,5-0.236,23.263-.262,75.732,0,94,4.666,2.666,14.334,8.334,19,11,6.666-3.333,11.334-7.667,18-11V95l-9-4c0.667-1,3.333-2,4-3,6.972-3.67,9.12-4.913,14-7l7,4V200c-6,3.666-11,7.334-17,11,0.667,0,5.333,3,6,3,14.955,9.344,26.726,17.057,41,24,26.664-15.332,55.336-31.668,82-47V95c-13.676-7.1-73.766-42.662-82-47-12.665,6.666-26.335,15.334-39,22-5-3-11-6-16-9,0.333-.667,3.667-2.333,4-3,19.921-10.9,31.359-16.813,52-29,32.33,19,65.67,38,98,57,0.206,24,.191,92.735,0,114-30.7,15.984-66.67,38.668-99,57l-65-37c-22.022,13.927-38.662,24.221-63,37-32.33-18.665-66.67-37.335-99-56V85Zm65,37c6,3,11,6,17,9-0.333.667-5.667,3.333-6,4-6.574,3.808-3.137,1.78-10,6-5.6-3.282-16.333-9.333-16-10C185.666,72.334,191.334,68.666,196,66Z"
            />
          </svg>
        </div>
        <h3 class="panel-title">{{ 'auth.sign_up.form.title' | translate }}</h3>
      </div>
      <div class="panel-body">
        <xp-form-validation type="User">
          <form
            role="form"
            name="signupForm"
            #form="ngForm"
            [attr.data-action-url]="PIPEDRIVE_URL"
            id="free-trial-form"
          >
            <input type="hidden" name="retURL" [value]="PIPEDRIVE_RETURN_URL" />
            <input type="hidden" name="formId" value="dashboard_free_trial" />
            <fieldset>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.name' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.name"
                  [placeholder]="'auth.sign_up.form.placeholders.name' | translate"
                  name="ad437a8f5ec7297efb125cca8d4792d7f34ec617"
                  type="text"
                  autofocus
                />
              </xp-form-group>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.email' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.email"
                  [placeholder]="'auth.sign_up.form.placeholders.email' | translate"
                  name="a53b75318b9a01eddadd7d3353bbd5d832d022"
                  type="email"
                  maxlength="254"
                />
              </xp-form-group>
              <xp-form-group>
                <label>{{ 'auth.sign_up.form.labels.company' | translate }}</label>
                <input
                  class="form-control"
                  [(ngModel)]="credentials.company"
                  [placeholder]="'auth.sign_up.form.placeholders.email' | translate"
                  name="7361f587f91f5330d9b17472abe6244fd9cd3da6"
                  type="text"
                  maxlength="254"
                />
              </xp-form-group>
            </fieldset>
            <xp-submit-button
              (submitForm)="submit()"
              classNames="btn-lg btn-block btn-primary"
              [isFormValid]="form.valid"
              [isFormSubmitting]="isLoading"
              [updateText]="'auth.sign_up.form.actions.sign_up' | translate"
            ></xp-submit-button>
          </form>
        </xp-form-validation>
      </div>
      <div class="panel-footer text-center panel-links">
        <span>{{ 'auth.sign_up.form.labels.have_account' | translate }}</span>
        <a (click)="goToSignIn()">{{ 'auth.sign_up.form.actions.sign_in' | translate }}</a>
      </div>
    </div>
    <help-menu></help-menu>
  `,
})
export class SignUpFreeTrialComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  credentials: Credentials = {
    email: '',
    password: '',
  };
  isLoading = false;
  PIPEDRIVE_URL = environment.PIPEDRIVE_URL;
  PIPEDRIVE_RETURN_URL = environment.PIPEDRIVE_RETURN_URL;
  utm_campaign = '';
  utm_medium = '';
  utm_source = '';

  constructor(private usersResource: UsersResource, private router: Router) {
    // @ts-ignore
    window.email_required = true;
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.utm_campaign = urlParams.get('utm_campaign');
    this.utm_medium = urlParams.get('utm_medium');
    this.utm_source = urlParams.get('utm_source');

    if (document.referrer.includes('appexchange.salesforce.com') && this.utm_source) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].identify({
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });

      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].track('appexchange.salesforce.com visitor', {
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });
    }
  }

  submit() {
    const form = document.querySelector('#free-trial-form') as HTMLFormElement;
    this.isLoading = true;

    if (form) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].identify({
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
      });

      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['woopra'].track('Free Trial Sign Up', {
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        utm_source: this.utm_source,
        name: this.credentials.name,
        email: this.credentials.email,
        company: this.credentials.company,
      });

      fetch(form.getAttribute('data-action-url'), {
        method: 'POST',
        body: new URLSearchParams(new FormData(form) as any),
        mode: 'no-cors',
      }).then(() => {
        this.usersResource.save({ ...this.credentials, grant_type: 'password' }).subscribe({
          next: () => {
            this.isLoading = false;
            this.router.navigate(['/auth/thank-you']);
          },
          error: () => {
            this.isLoading = false;
          },
        });
      });
    }
  }

  goToSignIn() {
    this.router.navigate(['/auth/login']);
  }
}
