import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Job } from '../../jobs.models';

@Component({
  selector: 'job-responses',
  template: `
    <div class="job-outputs job-content">
      <div class="table-view" *ngIf="item.responses.length">
        <table class="table table-bordered table-striped job-responses-table">
          <thead>
            <tr>
              <th>
                {{ item.responses[0][0] }}
              </th>
              <th>Code</th>
              <th>
                {{ item.responses[0][2] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let response of item.responses.slice(1); let index = index">
              <td>{{ response[0] }}</td>
              <td>{{ response[1] }}</td>
              <td class="body-cell">
                <pre>{{ isExpanded[index] ? responseBodiesExpanded[index] : responseBodies[index] }}</pre>
                <button
                  class="btn expand-button"
                  (click)="toggleExpand(index)"
                  *ngIf="responseBodiesExpanded[index] !== responseBodies[index]"
                >
                  {{ isExpanded[index] ? 'Collapse' : 'Expand' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <xp-loader *ngIf="areResponsesLoading"></xp-loader>
      <div class="alert alert-warning errors-notify" *ngIf="responsesErrorMessage" style="margin: 0 20px;">
        {{ responsesErrorMessage }}
      </div>
    </div>
  `,
})
export class JobResponsesComponent implements OnChanges {
  @Input() item: Job;
  @Input() areResponsesLoading: boolean;
  @Input() responsesErrorMessage: string;
  isExpanded: boolean[] = [];
  responseBodies: string[] = [];
  responseBodiesExpanded: string[] = [];

  ngOnChanges() {
    this.item.responses.slice(1).forEach((response, index) => {
      const body = this.generateResponseBody(response, index);
      this.responseBodiesExpanded[index] = body;
      this.responseBodies[index] = body.length > 100 ? body.slice(0, 100) + '...' : body;
    });
  }

  generateResponseBody(response: string[], index): string {
    let body = response.slice(2).filter(Boolean).join(',"');

    try {
      body = JSON.stringify(JSON.parse(body), null, 4);
    } catch {}

    return body;
  }

  toggleExpand(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }
}
