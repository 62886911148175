import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import CodeMirror from 'codemirror';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/tern/tern';

@Component({
  selector: 'code-editor',
  template: `
    <div class="code-editor">
      <textarea class="form-control" id="code-editor" [ngModel]="value" [attr.name]="name"></textarea>
    </div>
  `,
})
export class CodeEditorComponent implements AfterViewInit, OnChanges {
  @Input() value: string;
  @Input() name: string;
  @Input() options: any;
  @Output() valueChange = new EventEmitter<string>();

  textAreaElement: HTMLTextAreaElement;
  editor = null;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.textAreaElement = this.elementRef.nativeElement.querySelector('textarea');

    this.initEditor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value?.currentValue !== changes.value?.previousValue &&
      !changes.value?.firstChange &&
      this.editor &&
      this.value !== this.editor.getValue()
    ) {
      this.editor.setValue(this.value);
    }
  }

  initEditor() {
    const oldCodeMirror = document.querySelector('.CodeMirror');

    if (oldCodeMirror) {
      oldCodeMirror.parentElement.removeChild(oldCodeMirror);
    }

    setTimeout(() => {
      const defaultOptions = {
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/x-sql',
        placeholder: 'SELECT * FROM table',
      };

      const options = { ...defaultOptions, ...(this.options || {}) };

      this.editor = CodeMirror.fromTextArea(this.textAreaElement, options);

      if (this.value) this.editor.setValue(this.value);

      this.editor.on('change', (cm) => {
        this.value = cm.getValue();

        this.valueChange.emit(this.value);

        this.textAreaElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });

      this.editor.on('blur', (cm) => {
        this.value = cm.getValue();
        this.valueChange.emit(this.value);
      });
    }, 1000);
  }
}
