import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, FileMoverComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { ConnectionTypeName } from '../../../connections/connection.models';

@Component({
  selector: 'file-mover-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="sourceConnectionStep">
          <label for="source_connection">Source connection</label>
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.source_connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectSourceConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>

          <div class="database-source-editor">
            <xp-form-validation type="FileMover">
              <form class="cloud-storage-source-editor" name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div
                    class="col-md-7"
                    *ngIf="
                      rawComponent.source_connection.type !== ConnectionTypeName.sftp &&
                      rawComponent.source_connection.type !== ConnectionTypeName.ftps
                    "
                  >
                    <xp-form-group
                      *ngIf="
                        [
                          ConnectionTypeName.s3,
                          ConnectionTypeName.gs,
                          ConnectionTypeName.gsv2,
                          ConnectionTypeName.azureBlobStorage,
                        ].includes(rawComponent.source_connection.type)
                      "
                    >
                      <label for="bucket" *ngIf="hasSourceBucket || hasSourceContainer">
                        <span *ngIf="hasSourceBucket">{{
                          'cloud-storage-source-editor.form.labels.bucket' | translate
                        }}</span>
                        <span *ngIf="hasSourceContainer">{{
                          'cloud-storage-source-editor.form.labels.container' | translate
                        }}</span>
                      </label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket"
                        id="bucket"
                        [ngModel]="rawComponent.source_bucket"
                        (ngModelChange)="onValueChange($event, 'source_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-s3' | translate"
                        *ngIf="rawComponent.source_connection.type === ConnectionTypeName.s3"
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket_gc"
                        id="bucket"
                        [ngModel]="rawComponent.source_bucket"
                        (ngModelChange)="onValueChange($event, 'source_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-gs' | translate"
                        *ngIf="
                          rawComponent.source_connection.type === ConnectionTypeName.gs ||
                          rawComponent.source_connection.type === ConnectionTypeName.gsv2
                        "
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="container_azureblobstorage"
                        id="bucket"
                        [ngModel]="rawComponent.source_bucket"
                        (ngModelChange)="onValueChange($event, 'source_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.container' | translate"
                        *ngIf="rawComponent.source_connection.type === ConnectionTypeName.azureBlobStorage"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <xp-form-group>
                      <label for="path">{{ 'cloud-storage-source-editor.form.labels.path' | translate }}</label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="path"
                        id="path"
                        [ngModel]="rawComponent.source_path"
                        (ngModelChange)="onValueChange($event, 'source_path')"
                        [placeholder]="sourcePathPlaceholder | translate"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 5px; margin-top: 10px;">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.delete_files_from_source"
                        (ngModelChange)="onValueChange($event, 'delete_files_from_source')"
                        name="use_pagination"
                        [labelText]="'cloud-storage-source-editor.form.labels.delete_files_from_source' | translate"
                      ></xp-input-checkbox>
                    </div>
                    <div class="form-group">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.fail_on_empty_source"
                        (ngModelChange)="onValueChange($event, 'fail_on_empty_source')"
                        name="use_pagination"
                        [labelText]="'cloud-storage-source-editor.form.labels.fail_on_empty_source' | translate"
                      ></xp-input-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>

        <xp-step [step]="destinationConnectionStep">
          <label for="destination_connection">Destination connection</label>
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.destination_connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectDestinationConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>

          <div class="database-source-editor">
            <xp-form-validation type="FileMover">
              <form class="cloud-storage-source-editor" name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div
                    class="col-md-6"
                    *ngIf="
                      rawComponent.destination_connection.type !== ConnectionTypeName.sftp &&
                      rawComponent.destination_connection.type !== ConnectionTypeName.ftps
                    "
                  >
                    <xp-form-group
                      *ngIf="
                        [
                          ConnectionTypeName.s3,
                          ConnectionTypeName.gs,
                          ConnectionTypeName.gsv2,
                          ConnectionTypeName.azureBlobStorage,
                        ].includes(rawComponent.destination_connection.type)
                      "
                    >
                      <label for="bucket" *ngIf="hasDestinationBucket || hasDestinationContainer">
                        <span *ngIf="hasDestinationBucket">{{
                          'cloud-storage-source-editor.form.labels.destination_bucket' | translate
                        }}</span>
                        <span *ngIf="hasDestinationContainer">{{
                          'cloud-storage-source-editor.form.labels.destination_container' | translate
                        }}</span>
                      </label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket"
                        id="bucket"
                        [ngModel]="rawComponent.destination_bucket"
                        (ngModelChange)="onValueChange($event, 'destination_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-s3' | translate"
                        *ngIf="rawComponent.destination_connection.type === ConnectionTypeName.s3"
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket_gc"
                        id="bucket"
                        [ngModel]="rawComponent.destination_bucket"
                        (ngModelChange)="onValueChange($event, 'destination_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-gs' | translate"
                        *ngIf="
                          rawComponent.destination_connection.type === ConnectionTypeName.gs ||
                          rawComponent.destination_connection.type === ConnectionTypeName.gsv2
                        "
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="container_azureblobstorage"
                        id="bucket"
                        [ngModel]="rawComponent.destination_bucket"
                        (ngModelChange)="onValueChange($event, 'destination_bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.container' | translate"
                        *ngIf="rawComponent.destination_connection.type === ConnectionTypeName.azureBlobStorage"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <xp-form-group>
                      <label for="path"
                        >{{ 'cloud-storage-source-editor.form.labels.destination_path' | translate }}
                        <i
                          class="fa fa-info-circle"
                          matTooltip="Please ensure the directory exists."
                          matTooltipPosition="above"
                          matTooltipClass="above wide-400"
                        >
                        </i
                      ></label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="path"
                        id="path"
                        [ngModel]="rawComponent.destination_path"
                        (ngModelChange)="onValueChange($event, 'destination_path')"
                        [placeholder]="destinationPathPlaceholder | translate"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 5px; margin-top: 10px;">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.compression_type === 'zip'"
                        (ngModelChange)="onCompressionTypeZip($event)"
                        name="compression_type"
                        [labelText]="'cloud-storage-source-editor.form.labels.compression_type' | translate"
                      ></xp-input-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="operatorEditorStep">
          <div class="workflow-operator-editor">
            <div class="form-group">
              <div>
                <label
                  ><strong>{{ 'run-package-editor.labels.operator' | translate }}</strong></label
                >
              </div>
              <div class="form-group-options">
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-and"
                    value="and"
                  />
                  <label for="operator-and">{{ 'run-package-editor.labels.and' | translate }}</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    [ngModel]="rawComponent.operator"
                    (ngModelChange)="onValueChange($event, 'operator')"
                    name="operator"
                    id="operator-or"
                    value="or"
                  />
                  <label for="operator-or">{{ 'run-package-editor.labels.or' | translate }}</label>
                </div>
              </div>
            </div>
          </div>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class FileMoverEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: FileMoverComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  sourceConnectionStep: Step = getStep({ active: true });
  destinationConnectionStep: Step = getStep({});
  operatorEditorStep: Step = getStep({});

  hasSourceContainer = false;
  hasSourceBucket = false;
  hasDestinationContainer = false;
  hasDestinationBucket = false;

  data = null;
  errorMessage = null;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private connectionItemsResource: ConnectionItemsResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.onValidityChange();
    });

    this.sourceConnectionStep = getStep({
      title: 'Source properties',
      activeTitle: 'Define your source location',
      valid: !!this.rawComponent.source_connection?.id,
      active: true,
    });

    this.destinationConnectionStep = getStep({
      title: 'Destination properties',
      activeTitle: 'Define your destination location',
      valid: !!this.rawComponent.destination_connection?.id,
    });

    this.operatorEditorStep = getStep({
      title: 'Execute task if',
      activeTitle: 'Choose task execution',
      valid: true,
      tags: this.getComponentOperatorTags(this.rawComponent.operator),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rawComponent) {
      const newComponent = changes.rawComponent.currentValue;
      const oldComponent = changes.rawComponent.previousValue;

      if (newComponent?.source_connection?.type) {
        switch (newComponent?.source_connection?.type) {
          case ConnectionTypeName.rackspace:
          case ConnectionTypeName.softlayer:
          case ConnectionTypeName.swift:
            this.hasSourceContainer = true;
            this.hasSourceBucket = false;
            break;
          case ConnectionTypeName.sftp:
          case ConnectionTypeName.ftps:
            this.hasSourceContainer = false;
            this.hasSourceBucket = false;
            break;
          case ConnectionTypeName.hdfs:
            this.hasSourceContainer = false;
            this.hasSourceBucket = false;
            break;
          case ConnectionTypeName.s3:
          case ConnectionTypeName.gs:
          case ConnectionTypeName.gsv2:
            this.hasSourceContainer = false;
            this.hasSourceBucket = true;
            break;
          case ConnectionTypeName.azureBlobStorage:
            this.hasSourceContainer = true;
            this.hasSourceBucket = false;
            break;
          default:
            this.hasSourceContainer = false;
            this.hasSourceBucket = false;
            break;
        }
      }

      if (newComponent?.destination_connection?.type) {
        switch (newComponent?.destination_connection?.type) {
          case ConnectionTypeName.rackspace:
          case ConnectionTypeName.softlayer:
          case ConnectionTypeName.swift:
            this.hasDestinationContainer = true;
            this.hasDestinationBucket = false;
            break;
          case ConnectionTypeName.sftp:
          case ConnectionTypeName.ftps:
            this.hasDestinationContainer = false;
            this.hasDestinationBucket = false;
            break;
          case ConnectionTypeName.hdfs:
            this.hasDestinationContainer = false;
            this.hasDestinationBucket = false;
            break;
          case ConnectionTypeName.s3:
          case ConnectionTypeName.gs:
          case ConnectionTypeName.gsv2:
            this.hasDestinationContainer = false;
            this.hasDestinationBucket = true;
            break;
          case ConnectionTypeName.azureBlobStorage:
            this.hasDestinationContainer = true;
            this.hasDestinationBucket = false;
            break;
          default:
            this.hasDestinationContainer = false;
            this.hasDestinationBucket = false;
            break;
        }
      }
    }
  }

  get sourcePathPlaceholder(): string {
    switch (this.rawComponent.source_connection?.type) {
      case ConnectionTypeName.s3:
      case ConnectionTypeName.azureBlobStorage:
        return 'cloud-storage-source-editor.form.placeholders.file-mover-path-s3';
      case ConnectionTypeName.gs:
      case ConnectionTypeName.gsv2:
        return 'cloud-storage-source-editor.form.placeholders.file-mover-path-gs';
      case ConnectionTypeName.sftp:
        return 'cloud-storage-source-editor.form.placeholders.file-mover-path-sftp';
      default:
        return 'cloud-storage-source-editor.form.placeholders.file-mover-path';
    }
  }

  get destinationPathPlaceholder(): string {
    switch (this.rawComponent.destination_connection?.type) {
      case ConnectionTypeName.s3:
      case ConnectionTypeName.azureBlobStorage:
        return 'cloud-storage-source-editor.form.placeholders.dest-path-s3';
      case ConnectionTypeName.gs:
      case ConnectionTypeName.gsv2:
        return 'cloud-storage-source-editor.form.placeholders.dest-path-gs';
      case ConnectionTypeName.sftp:
        return 'cloud-storage-source-editor.form.placeholders.dest-path-sftp';
      default:
        return 'cloud-storage-source-editor.form.placeholders.dest-path';
    }
  }

  onCompressionTypeZip(value: boolean) {
    this.onValueChange(value ? 'zip' : null, 'compression_type');
  }

  onValidityChange() {
    const isValid = this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectSourceConnection(source_connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { source_connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { source_connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(source_connection.type)}" alt="${
      source_connection.name
    }" />`;

    this.sourceConnectionStep.tags = [
      {
        name: `${img}<b>${escape(source_connection.name)}</b>`,
      },
    ];

    this.sourceConnectionStep = { ...this.sourceConnectionStep, valid: true };
  }

  onSelectDestinationConnection(destination_connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { destination_connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { destination_connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(destination_connection.type)}" alt="${
      destination_connection.name
    }" />`;

    this.destinationConnectionStep.tags = [
      {
        name: `${img}<b>${escape(destination_connection.name)}</b>`,
      },
    ];

    this.destinationConnectionStep = { ...this.destinationConnectionStep, valid: true };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  // eslint-disable-next-line class-methods-use-this
  getComponentOperatorTags(operator: string) {
    if (operator === 'and') {
      return [
        {
          name: 'all preceding conditions evaluate to true (AND)',
        },
      ];
    }
    if (operator === 'or') {
      return [
        {
          name: 'one of the preceding conditions evaluate to true (OR)',
        },
      ];
    }

    return [];
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }

  protected readonly ConnectionTypeName = ConnectionTypeName;
}
