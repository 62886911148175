import { Component, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../store';
import { UsersResource } from '../../account/resources/users.resource';
import { NotifyService } from '../../common/services/notify.service';
import { environment } from '../../../environments/environment';

const secretPlaceHolder = 'the secret key';

interface EnrollResponse {
  mfa_qr: string;
  mfa_secret: string;
  message?: string;
}

@Component({
  selector: 'settings-mfa',
  template: `
    <div class="settings-body settings-mfa">
      <h2>{{ 'user.settings.mfa.title' | translate }}</h2>
      <div class="row" *ngIf="isMfaEnabled">
        <div class="col-md-10 col-sm-12" style="text-align: left; padding-bottom: 5px;">
          <p class="alert alert-info">
            <i class="fa fa-lock"></i>
            <span [innerHTML]="'user.settings.mfa.labels.mfa_enabled' | translate"></span>
          </p>
        </div>
      </div>

      <!-- we also want to show the password input when they want to turn OFF the 2FA -->
      <div *ngIf="!mfaQr" class="row">
        <div class="col-md-10 col-sm-12">
          <form name="userForm" (ngSubmit)="toggle()">
            <div class="form-group">
              <label for="current_password">{{ getInfoText() | translate }}</label>
              <xp-input
                type="password"
                placeholder="Password"
                id="current_password"
                name="current_password"
                class="form-control"
                [(ngModel)]="credentials.current_password"
                [disable]="true"
                required
              ></xp-input>
              <div class="gray-text" style="margin-top: 10px;">
                Two-factor authentication must be configured via Integrate.io
              </div>
            </div>

            <xp-submit-button
              classNames="btn-md btn-success"
              updateText="{{ getActionText() | translate }} {{ 'user.settings.mfa.title' | translate }}"
              [isFormValid]="false"
              [isFormSubmitting]="isSubmitting"
            ></xp-submit-button>
          </form>
        </div>
      </div>

      <br />

      <!-- 2FA QR and code confirmation screen -->

      <!-- steps could be implemented with the <steps><step> ... </step></steps> directive, but how? -->

      <div *ngIf="mfaQr" class="row">
        <div class="col-md-10 col-sm-12">
          <b>1. {{ 'user.settings.mfa.steps.get_app.title' | translate }}</b>
          <span [innerHTML]="'user.settings.mfa.steps.get_app.subtitle' | translate"></span>

          <hr />

          <b>2. {{ 'user.settings.mfa.steps.scan_qr.title' | translate }}</b>
          Alternatively, you can type
          <span (click)="toggleSecret()" style="color: #177af0; cursor: pointer;">{{ mfaSecretDisplay }}</span> into
          your authenticator app.

          <div>
            <img [src]="mfaQr" />
          </div>

          <hr />

          <b>3. {{ 'user.settings.mfa.steps.verify_device.title' | translate }}</b>
          {{ 'user.settings.mfa.steps.verify_device.subtitle' | translate }}
          <br />
          <br />
          <div>
            <form name="userForm" (ngSubmit)="toggle()">
              <div class="form-group">
                <input
                  type="text"
                  id="mfa_confirm"
                  [(ngModel)]="credentials.mfa_code"
                  name="mfa_code"
                  class="form-control"
                  maxlength="6"
                />
              </div>
              <xp-submit-button
                classNames="btn-md btn-success"
                updateText="{{ 'user.settings.mfa.actions.enable' | translate }} {{
                  'user.settings.mfa.title' | translate
                }}"
                [isFormValid]="true"
                [isFormSubmitting]="isSubmitting"
              ></xp-submit-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SettingsMfaComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  isSubmitting = false;
  mfaQr = null;
  mfaSecret = null;
  showMfaSecret = false;
  mfaSecretDisplay = secretPlaceHolder;
  isConfirming = false;
  isMfaEnabled = false;
  credentials = {
    current_password: '',
    mfa_code: '',
  };

  constructor(
    private usersResource: UsersResource,
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.usersResource.get().subscribe({
      next: (user) => {
        this.isMfaEnabled = user.settings.mfa_enabled;
      },
      error: () => {
        this.notify.error('An error occurred while loading user data.');
      },
    });
  }

  enrollCallback(response: EnrollResponse) {
    this.mfaSecret = response?.mfa_secret;
    this.mfaQr = response?.mfa_qr;
    this.isConfirming = true;
  }

  confirmCallback(response: EnrollResponse) {
    this.notify.success(response?.message);
    this.mfaQr = null;
    this.mfaSecret = null;
    this.isMfaEnabled = true;
    this.isConfirming = false;
    this.credentials.current_password = '';
    this.credentials.mfa_code = '';
  }

  unEnrollCallback(response: EnrollResponse) {
    this.notify.success(response?.message);
    this.isMfaEnabled = false;
    this.isConfirming = false;
    this.credentials.current_password = '';
    this.credentials.mfa_code = '';
  }

  getActionText(): string {
    return this.isMfaEnabled ? 'user.settings.mfa.actions.disable' : 'user.settings.mfa.actions.enable';
  }

  getInfoText(): string {
    return this.isMfaEnabled ? 'user.settings.mfa.labels.lost_device' : 'user.settings.mfa.labels.encouragement';
  }

  toggleSecret() {
    this.showMfaSecret = !this.showMfaSecret;
    this.mfaSecretDisplay = this.showMfaSecret ? this.mfaSecret : secretPlaceHolder;
  }

  toggle() {
    // if MFA is on, the user wants to remove it; if it's off, the user is turning it on
    const endpoint = this.isMfaEnabled ? '/user/mfa/remove' : '/user/mfa';
    const url = `${environment.API_URL}${endpoint}`;
    const data = this.credentials;

    const request = this.isConfirming ? this.http.put(url, data) : this.http.post(url, data);

    this.isSubmitting = true;

    request.subscribe({
      next: (response: EnrollResponse) => {
        this.isSubmitting = false;
        if (this.isConfirming) {
          this.confirmCallback(response);
        } else if (this.isMfaEnabled) {
          // if mfa was enabled then this is a call to disable it
          this.unEnrollCallback(response);
        } else {
          this.enrollCallback(response);
        }
      },
      error: (response) => {
        this.isSubmitting = false;
        const errorData = response ? response.error : null;
        let errorMessage = this.translate.instant('auth.sign_in.labels.invalid_response');

        if (errorData && errorData.message) {
          errorMessage = errorData.message;
          this.notify.error(errorMessage);
        }
      },
    });
  }
}
