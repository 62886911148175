import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Package } from '../packages/package.models';
import { PackagesResource } from '../packages/resources/packages.resource';
import { setPackageFromJson } from './store/package.actions';
import { AppState } from '../store';
import { NotifyService } from '../common/services/notify.service';

@Injectable({ providedIn: 'root' })
export class PackageDesignerResolver {
  constructor(
    private packagesResource: PackagesResource,
    private store: Store<AppState>,
    private notify: NotifyService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Package> {
    const { account_id } = route.parent.params;
    const { package_id, version } = route.params;
    const packageRequest = version
      ? this.packagesResource.version(
          package_id,
          version,
          {
            include: 'flow',
          } as any,
          account_id,
        )
      : this.packagesResource.get(
          package_id,
          {
            include: 'flow',
          } as any,
          account_id,
        );

    return packageRequest.pipe(
      tap((packageItem) => {
        this.store.dispatch(setPackageFromJson({ packageItem }));
        setTimeout(() => {
          document.title = `${packageItem.name} · ETL & Reverse ETL`;
        });
      }),
      first(),
      catchError(() => {
        this.notify.error('An error occurred while loading package data.');
        return new Observable<any>((subscriber) => {
          subscriber.next({} as any);
          subscriber.complete();
        });
      }),
    );
  }
}
