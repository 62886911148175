import { User } from '../user.models';
import { environment } from '../../../environments/environment';

export function redirectToIntegrateIOLogin() {
  if (window.localStorage.getItem('isIntegrateIOUser') && window.location.hostname !== 'localhost') {
    // @ts-ignore
    window.location = `${environment.INTEGRATEIO_URL}/auth/login`;
  }
}

export function setIntegrateIOFlag(user: User) {
  if (user.integrateio_id) {
    window.localStorage.setItem('isIntegrateIOUser', 'true');
  } else {
    window.localStorage.removeItem('isIntegrateIOUser');
  }
}

export function setSSOFlag(user: User) {
  if (user.settings && (user.settings.sso_enabled || user.settings.hide_integrateio_migration)) {
    window.localStorage.setItem('isEnterpriseSSOUser', 'true');
  } else {
    window.localStorage.removeItem('isEnterpriseSSOUser');
  }
}

export function getSSOFlag(): boolean {
  return !!window.localStorage.getItem('isEnterpriseSSOUser');
}

export function getIntegrateIOFlag(): boolean {
  return !!window.localStorage.getItem('isIntegrateIOUser');
}
