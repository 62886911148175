// Searches for a destination field by name in the list of fields ignoring spaces, underscores and hyphens
import { Field } from '../package.models';
import { DesignerSchemaFieldI } from '../models/designer-schema-field.model';

export function findDestinationField(
  fieldNameParameter: string,
  fieldsParameter: Array<Field | DesignerSchemaFieldI>,
  isSalesforce = false,
): Field | DesignerSchemaFieldI {
  let fieldName = fieldNameParameter;

  if (isSalesforce && fieldName.endsWith('__c') && fieldName.length > 3) {
    fieldName = fieldName.slice(0, -3);
  }

  let fields = fieldsParameter;
  if (isSalesforce) {
    fields = fields.map((field) => ({
      ...field,
      name: field.name.endsWith('__c') ? field.name.slice(0, -3) : field.name,
    }));
  }

  let fieldIndex = fields.findIndex((item) => item.name === fieldName);

  if (fieldIndex === -1) {
    fieldIndex = fields.findIndex((item) => item.name.toLowerCase() === fieldName.toLowerCase());
  }

  if (fieldIndex === -1) {
    fieldIndex = fields.findIndex(
      (item) => item.name.toLowerCase().replace(/[\s_-]/g, '') === fieldName.toLowerCase().replace(/[\s_-]/g, ''),
    );
  }

  return fieldsParameter[fieldIndex];
}

export function mergeFields(recordsCopy: DesignerSchemaFieldI[], schemaFields: Field[]) {
  return [
    ...recordsCopy,
    ...schemaFields.filter((field) => !recordsCopy.find((item) => item.field_name === field.name)),
  ];
}
