/* eslint-disable @typescript-eslint/dot-notation */
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'apps-toolbar',
  template: `
    <div class="apps-toolbar">
      <div
        class="apps-button"
        [ngClass]="{ active: isMenuOpen }"
        [matMenuTriggerFor]="appsDropdown"
        (menuOpened)="isMenuOpen = true"
        (menuClosed)="isMenuOpen = false"
      >
        <i class="icon"></i>ETL & Reverse ETL<i class="fa fa-angle-down"></i>
      </div>
      <mat-menu #appsDropdown="matMenu" class="apps-dropdown-menu">
        <a
          class="mat-dropdown-menu-item app-item elt_cdc"
          mat-menu-item
          [href]="getLink('elt_cdc')"
          target="_blank"
          (click)="clickOn('ELT & CDC')"
        >
          <div class="icon"></div>
          ELT & CDC
        </a>
        <a
          class="mat-dropdown-menu-item app-item api-generation"
          mat-menu-item
          [href]="getLink('api_generation')"
          target="_blank"
          (click)="clickOn('Api Generation')"
        >
          <div class="icon"></div>
          API Generation
        </a>
      </mat-menu>
    </div>
  `,
})
export class AppsToolbarComponent {
  isMenuOpen = false;

  // eslint-disable-next-line class-methods-use-this
  clickOn(event: string) {
    (window['woopra'] as any).identify();
    (window['woopra'] as any).track(`Apps menu click: ${event}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getLink(item: string): string {
    return `${environment.INTEGRATEIO_URL}/?utm_source=Xplenty&utm_medium=drop_down&utm_campaign=ui_Integration&app_id=${item}`;
  }
}
